import axios from "axios";

export default () => {
  return {
    showForm: false,
    busy: false,
    submitted: false,
    count: 0,

    init() {},

    submit() {
      var data = new FormData(this.$refs.form);
      let isValid = this.$refs.form.checkValidity();

      if (!isValid) {
      } else {
        this.busy = true;

        axios
          .post("/", data)
          .then(response => {
            if (response.data.success === true) {
              this.count = response.data.count;
              this.submitted = true;
            } else {
              this.busy = false;
              alert(response.data.error);
            }
          })
          .catch(error => {
            this.busy = false;
          });
      }
    },
  };
};
