export default () => ({
  mobileOpen: false,
  langSelector: false,
  minimize: false,
  minimzeThreshold: 200,
  desktop: true,

  init() {
    let options = {};
    let observer = new IntersectionObserver(this.handleIntersect.bind(this), options);

    let element = document.querySelector("#header-bottom");
    if (element) {
      observer.observe(element);
    }

    this.mediaQuery = window.matchMedia("(min-width: 1024px)");
    this.mediaQuery.addListener(this.handleMediaQueryChange.bind(this));
    this.handleMediaQueryChange(this.mediaQuery);
  },

  handleMediaQueryChange(e) {
    if (e.matches) {
      this.desktop = true;
    } else {
      this.desktop = false;
    }
  },

  handleIntersect(entries, observer) {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        this.minimize = false;
      } else {
        this.minimize = true;
      }
    });
  },

  toggleMobile() {
    this.mobileOpen = !this.mobileOpen;
  },

  openMobile() {
    this.mobileOpen = true;
  },

  closeMobile() {
    this.mobileOpen = false;
  },

  toggleLang() {
    this.langSelector = !this.langSelector;
  },

  showLang() {
    this.langSelector = true;
  },

  hideLang() {
    this.langSelector = false;
  },
});
