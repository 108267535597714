export default () => {
  return {
    hidden: false,
    init() {
      this.button = this.$el.querySelector("a");

      let options = {};
      let observer = new IntersectionObserver(this.handleIntersect.bind(this), options);

      observer.observe(this.$el);

      setTimeout(() => {
        this.$el.classList.remove("opacity-0");
      }, 1000);
    },

    handleIntersect(entries, observer) {
      entries.forEach(entry => {
        this.hidden = entry.isIntersecting;
      });
    },
  };
};
