export default function (lookup) {
  return {
    providers: [],
    invalid: false,
    lookupType: lookup,
    utmsource: "EUC-HEE",
    zipCode: this.$persist(""),

    init() {
      if (this.zipCode != "") {
        this.submit();
      }
    },

    reset() {
      this.zipCode = "";
      this.providers = [];
    },

    submit() {
      this.matches = Alpine.store("providers").getProviders(this.zipCode);

      this.providers = this.matches.map(element => {
        let url = element.default;
        if (this.lookupType in element) {
          url = element[this.lookupType];
        }

        if (url.indexOf("?") !== -1) {
          url = `${url}&utm_source=${this.utmsource}`;
        } else {
          url = `${url}?utm_source=${this.utmsource}`;
        }

        return {
          provider: element.provider,
          url: url,
        };
      });

      this.invalid = this.providers.length == 0;
      return true;
    },
  };
}
