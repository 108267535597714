export default () => {
  return {
    getProviders(zipCode) {
      zipCode = parseInt(zipCode);

      let providers = this.allproviders.filter(provider => {
        return provider.zip.includes(zipCode);
      });
      return providers;
    },

    allproviders: [
      {
        provider: "BayREN",
        "home-energy-efficiency-web": "https://bayrenresidential.org",
        assessments: "https://bayrenresidential.org",
        heating: "https://bayrenresidential.org",
        "water-heater": "https://bayrenresidential.org",
        cooling: "https://bayrenresidential.org",
        insulation: "https://bayrenresidential.org",
        "air-sealing": "https://bayrenresidential.org",
        lighting: "https://bayrenresidential.org",
        "efficient-appliances-residental": "https://bayrenresidential.org",
        "efficient-appliances-small-business": "bayren.org",
        solar: "https://bayrenresidential.org",
        programs: "https://bayrenresidential.org",
        "default-hee": "https://bayrenresidential.org",
        "the-movement": "https://bayrenresidential.org",
        "energy-heroes-fabian-rodriguez": "https://bayrenresidential.org",
        default: "https://bayrenresidential.org",
        contractor: "https://www.bayrenresidential.org/find-a-contractor",
        "assess-your-energy": "https://bayrenresidential.org",
        "do-it-yourself": "https://bayrenresidential.org",
        "water-heating": "https://bayrenresidential.org",
        appliances: "https://bayrenresidential.org",
        "energy-management-tools": "https://bayrenresidential.org",
        rebates: "https://bayrenresidential.org",
        "home-improvements": "https://bayrenresidential.org",
        assistance: "https://bayrenresidential.org",
        financing: "https://bayrenresidential.org",
        esa: "https://bayrenresidential.org/",
        zip: [
          94002, 94005, 94010, 94011, 94014, 94015, 94016, 94017, 94018, 94019, 94020, 94021, 94022, 94023, 94024, 94025, 94026, 94027, 94028, 94030, 94035, 94037, 94038, 94039, 94040, 94041, 94042, 94043, 94044, 94060, 94061, 94062, 94063, 94064, 94065, 94066, 94070, 94074, 94080, 94083, 94085,
          94086, 94087, 94088, 94089, 94102, 94103, 94104, 94105, 94107, 94108, 94109, 94110, 94111, 94112, 94114, 94115, 94116, 94117, 94118, 94119, 94120, 94121, 94122, 94123, 94124, 94125, 94126, 94127, 94128, 94129, 94130, 94131, 94132, 94133, 94134, 94137, 94139, 94140, 94141, 94142, 94143,
          94144, 94145, 94146, 94147, 94151, 94158, 94159, 94160, 94161, 94163, 94164, 94172, 94177, 94188, 94305, 94401, 94402, 94403, 94404, 94497, 94501, 94502, 94503, 94505, 94506, 94507, 94508, 94509, 94510, 94511, 94512, 94513, 94514, 94515, 94516, 94517, 94518, 94519, 94520, 94521, 94522,
          94523, 94524, 94525, 94526, 94527, 94528, 94529, 94530, 94531, 94533, 94534, 94535, 94536, 94537, 94538, 94539, 94540, 94541, 94542, 94543, 94544, 94545, 94546, 94547, 94548, 94549, 94550, 94551, 94552, 94553, 94555, 94556, 94557, 94558, 94559, 94560, 94561, 94562, 94563, 94564, 94565,
          94566, 94567, 94568, 94569, 94570, 94571, 94572, 94573, 94574, 94575, 94576, 94577, 94578, 94579, 94580, 94581, 94582, 94583, 94585, 94586, 94587, 94588, 94589, 94590, 94591, 94592, 94595, 94596, 94597, 94598, 94599, 94601, 94602, 94603, 94604, 94605, 94606, 94607, 94608, 94609, 94610,
          94611, 94612, 94613, 94614, 94615, 94617, 94618, 94619, 94620, 94621, 94622, 94623, 94624, 94649, 94659, 94660, 94661, 94662, 94666, 94701, 94702, 94703, 94704, 94705, 94706, 94707, 94708, 94709, 94710, 94712, 94720, 94801, 94802, 94803, 94804, 94805, 94806, 94807, 94808, 94820, 94850,
          94901, 94903, 94904, 94912, 94913, 94914, 94915, 94920, 94922, 94923, 94924, 94925, 94926, 94927, 94928, 94929, 94930, 94931, 94933, 94937, 94938, 94939, 94940, 94941, 94942, 94945, 94946, 94947, 94948, 94949, 94950, 94951, 94952, 94953, 94954, 94955, 94956, 94957, 94960, 94963, 94964,
          94965, 94966, 94970, 94971, 94972, 94973, 94974, 94975, 94976, 94977, 94978, 94979, 94998, 94999, 95002, 95008, 95009, 95011, 95013, 95014, 95015, 95020, 95021, 95026, 95030, 95031, 95032, 95033, 95035, 95036, 95037, 95038, 95042, 95044, 95046, 95050, 95051, 95052, 95053, 95054, 95055,
          95056, 95070, 95071, 95101, 95103, 95106, 95108, 95109, 95110, 95111, 95112, 95113, 95115, 95116, 95117, 95118, 95119, 95120, 95121, 95122, 95123, 95124, 95125, 95126, 95127, 95128, 95129, 95130, 95131, 95132, 95133, 95134, 95135, 95136, 95138, 95139, 95140, 95141, 95148, 95150, 95151,
          95152, 95153, 95154, 95155, 95156, 95157, 95158, 95159, 95160, 95161, 95164, 95170, 95172, 95173, 95190, 95191, 95192, 95193, 95194, 95196, 95401, 95402, 95403, 95404, 95405, 95406, 95407, 95409, 95412, 95416, 95419, 95421, 95425, 95430, 95431, 95433, 95436, 95439, 95441, 95442, 95444,
          95446, 95448, 95450, 95452, 95462, 95465, 95471, 95472, 95473, 95476, 95480, 95486, 95487, 95492, 95497, 95620, 95625, 95687, 95688, 95690, 95694, 95696,
        ],
      },
      {
        provider: "Pacific Gas & Electric",
        "home-energy-efficiency-web": "https://www.pge.com/en_US/residential/customer-service/home-services/renovating-and-building/understanding-the-application-process/frequently-asked-questions.page",
        assessments: "https://www.pge.com/en_US/residential/your-account/account-management/understand-your-energy-usage/understand-your-energy-usage.page",
        heating: "https://www.pge.com/en_US/residential/rate-plans/how-rates-work/rate-changes/here-to-help/winter.page",
        "water-heater": "https://www.pge.com/myhome/saveenergymoney/solar/solarwaterheating/",
        cooling: "https://www.pge.com/en_US/residential/save-energy-money/resources/summer-tips/summer-tips.page",
        insulation: "https://www.pge.com/en_US/residential/save-energy-money/resources/everyday-tips/everyday-tips.page",
        "air-sealing": "https://www.pge.com/en_US/residential/save-energy-money/resources/everyday-tips/everyday-tips.page",
        lighting: "https://marketplace.pge.com/",
        "efficient-appliances-residental": "https://marketplace.pge.com/",
        "efficient-appliances-small-business": "https://marketplace.pge.com/",
        solar: "https://www.pge.com/myhome/saveenergymoney/solar/solarwaterheating/",
        programs: "https://www.pge.com/en_US/residential/save-energy-money/savings-programs/savings-programs-overview/savings-programs-overview.page",
        "default-hee": "https://www.pge.com",
        "the-movement": "https://www.pge.com/en_US/about-pge/environment/what-we-are-doing/putting-energy-efficiency-first/putting-energy-efficiency-first.page",
        "energy-heroes-fabian-rodriguez": "https://www.pge.com",
        default: "https://www.pge.com",
        rebates: "https://www.pge.com/en_US/residential/save-energy-money/savings-solutions-and-rebates/how-to-apply/how-to-apply.page",
        assistance: "https://www.pge.com/en_US/residential/save-energy-money/help-paying-your-bill/longer-term-assistance/care/care.page?WT.mc_id=Vanity_care",
        financing: "https://www.pge.com/en_US/business/save-energy-money/financing/energy-efficiency-financing/energy-efficiency-financing.page",
        contractor: "https://www.pge.com/en_US/small-medium-business/building-and-property-management/find-contractors-and-trade-professionals/find-a-service-provider.page?",
        "assess-your-energy": "https://www.pge.com/en_US/residential/your-account/account-management/understand-your-energy-usage/understand-your-energy-usage.page",
        "financing-assistance": "https://www.pge.com/en_US/business/save-energy-money/financing/energy-efficiency-financing/energy-efficiency-financing.page",
        "do-it-yourself": "https://www.pge.com/en_US/residential/your-account/account-management/understand-your-energy-usage/understand-your-energy-usage.page",
        "home-heating": "https://www.pge.com/en_US/residential/rate-plans/how-rates-work/rate-changes/here-to-help/winter.page",
        "home-cooling-and-ac": "https://www.pge.com/en_US/residential/save-energy-money/resources/summer-tips/summer-tips.page",
        "insulation-and-air-sealing": "https://www.pge.com/en_US/residential/save-energy-money/resources/everyday-tips/everyday-tips.page",
        appliances: "https://marketplace.pge.com/",
        "energy-management-tools": "https://www.pge.com/en_US/residential/save-energy-money/savings-solutions-and-rebates/how-to-apply/how-to-apply.page",
        "home-improvements": "https://www.pge.com/en_US/residential/save-energy-money/savings-programs/home-upgrade/find-contractors.page",
        "time-of-use": "https://www.pge.com/en_US/residential/rate-plans/rate-plan-options/time-of-use-base-plan/time-of-use-plan.page",
        esa: "https://www.pge.com/en_US/residential/save-energy-money/help-paying-your-bill/energy-reduction-and-weatherization/energy-savings-assistance-program/energy-savings-assistance-program.page",
        zip: [
          93101, 93105, 93110, 93111, 93117, 93201, 93203, 93204, 93206, 93210, 93212, 93219, 93220, 93224, 93230, 93232, 93234, 93239, 93241, 93242, 93245, 93246, 93249, 93250, 93251, 93252, 93254, 93256, 93263, 93265, 93266, 93268, 93272, 93274, 93276, 93277, 93279, 93280, 93282, 93286, 93291,
          93292, 93301, 93302, 93303, 93304, 93305, 93306, 93307, 93308, 93309, 93311, 93312, 93313, 93314, 93380, 93381, 93383, 93384, 93385, 93386, 93387, 93388, 93389, 93390, 93401, 93402, 93403, 93405, 93406, 93407, 93408, 93409, 93410, 93412, 93420, 93421, 93422, 93423, 93424, 93426, 93427,
          93428, 93429, 93430, 93432, 93433, 93434, 93435, 93436, 93437, 93438, 93440, 93441, 93442, 93443, 93444, 93445, 93446, 93447, 93448, 93449, 93450, 93451, 93452, 93453, 93454, 93455, 93456, 93457, 93458, 93460, 93461, 93463, 93464, 93465, 93475, 93483, 93561, 93601, 93602, 93603, 93604,
          93605, 93606, 93607, 93608, 93609, 93610, 93611, 93612, 93613, 93614, 93615, 93616, 93618, 93619, 93620, 93621, 93622, 93623, 93624, 93625, 93626, 93627, 93628, 93630, 93631, 93633, 93634, 93635, 93636, 93637, 93638, 93639, 93640, 93641, 93643, 93644, 93645, 93646, 93647, 93648, 93649,
          93650, 93651, 93652, 93653, 93654, 93656, 93657, 93660, 93661, 93662, 93664, 93665, 93666, 93667, 93668, 93669, 93670, 93673, 93675, 93701, 93702, 93703, 93704, 93705, 93706, 93707, 93708, 93709, 93710, 93711, 93712, 93714, 93715, 93716, 93717, 93718, 93720, 93721, 93722, 93723, 93724,
          93725, 93726, 93727, 93728, 93729, 93730, 93737, 93740, 93741, 93744, 93745, 93747, 93750, 93755, 93760, 93761, 93764, 93765, 93771, 93772, 93773, 93774, 93775, 93776, 93777, 93778, 93779, 93786, 93790, 93791, 93792, 93793, 93794, 93844, 93888, 93901, 93902, 93905, 93906, 93907, 93908,
          93912, 93915, 93920, 93921, 93922, 93923, 93924, 93925, 93926, 93927, 93928, 93930, 93932, 93933, 93940, 93942, 93943, 93944, 93950, 93953, 93954, 93955, 93960, 93962, 94002, 94005, 94010, 94011, 94013, 94014, 94015, 94016, 94017, 94018, 94019, 94020, 94021, 94022, 94023, 94024, 94025,
          94026, 94027, 94028, 94030, 94035, 94037, 94038, 94039, 94040, 94041, 94042, 94043, 94044, 94060, 94061, 94062, 94063, 94064, 94065, 94066, 94070, 94074, 94080, 94083, 94085, 94086, 94087, 94088, 94089, 94101, 94102, 94103, 94104, 94105, 94106, 94107, 94108, 94109, 94110, 94111, 94112,
          94114, 94115, 94116, 94117, 94118, 94119, 94120, 94121, 94122, 94123, 94124, 94125, 94126, 94127, 94128, 94129, 94130, 94131, 94132, 94133, 94134, 94135, 94136, 94137, 94138, 94139, 94140, 94141, 94142, 94143, 94144, 94145, 94146, 94147, 94150, 94151, 94152, 94153, 94154, 94155, 94156,
          94158, 94159, 94160, 94161, 94162, 94163, 94164, 94171, 94172, 94175, 94177, 94188, 94199, 94303, 94305, 94309, 94401, 94402, 94403, 94404, 94497, 94502, 94503, 94505, 94506, 94507, 94508, 94509, 94510, 94511, 94512, 94513, 94514, 94515, 94516, 94517, 94518, 94519, 94520, 94521, 94522,
          94523, 94524, 94525, 94526, 94527, 94528, 94529, 94530, 94531, 94533, 94534, 94535, 94536, 94537, 94538, 94539, 94540, 94541, 94542, 94543, 94544, 94545, 94546, 94547, 94548, 94549, 94550, 94551, 94552, 94553, 94555, 94556, 94557, 94558, 94559, 94560, 94561, 94562, 94563, 94564, 94565,
          94566, 94567, 94568, 94569, 94570, 94571, 94572, 94573, 94574, 94575, 94576, 94577, 94578, 94579, 94580, 94581, 94582, 94583, 94585, 94586, 94587, 94588, 94589, 94590, 94591, 94592, 94595, 94596, 94597, 94598, 94599, 94601, 94602, 94603, 94604, 94605, 94606, 94607, 94608, 94609, 94610,
          94611, 94612, 94613, 94614, 94615, 94617, 94618, 94619, 94620, 94621, 94622, 94623, 94624, 94649, 94659, 94660, 94661, 94662, 94666, 94701, 94702, 94703, 94704, 94705, 94706, 94707, 94708, 94709, 94710, 94712, 94720, 94801, 94802, 94803, 94804, 94805, 94806, 94807, 94808, 94820, 94850,
          94901, 94903, 94904, 94912, 94913, 94914, 94915, 94920, 94922, 94923, 94924, 94925, 94926, 94927, 94928, 94929, 94930, 94931, 94933, 94937, 94938, 94939, 94940, 94941, 94942, 94945, 94946, 94947, 94948, 94949, 94950, 94951, 94952, 94953, 94954, 94955, 94956, 94957, 94960, 94963, 94964,
          94965, 94966, 94970, 94971, 94972, 94973, 94975, 94976, 94977, 94978, 94979, 94998, 94999, 95001, 95002, 95003, 95004, 95005, 95006, 95007, 95008, 95009, 95010, 95011, 95012, 95013, 95014, 95015, 95017, 95018, 95019, 95020, 95021, 95023, 95024, 95026, 95030, 95031, 95032, 95033, 95035,
          95036, 95037, 95038, 95039, 95041, 95042, 95043, 95044, 95045, 95046, 95060, 95061, 95062, 95063, 95064, 95065, 95066, 95067, 95070, 95071, 95073, 95075, 95076, 95077, 95101, 95103, 95106, 95108, 95109, 95110, 95111, 95112, 95113, 95115, 95116, 95117, 95118, 95119, 95120, 95121, 95122,
          95123, 95124, 95125, 95126, 95127, 95128, 95129, 95130, 95131, 95132, 95133, 95134, 95135, 95136, 95138, 95139, 95140, 95141, 95148, 95150, 95151, 95152, 95153, 95154, 95155, 95156, 95157, 95158, 95159, 95160, 95161, 95164, 95170, 95172, 95173, 95190, 95191, 95192, 95193, 95194, 95196,
          95201, 95202, 95203, 95204, 95205, 95206, 95207, 95208, 95209, 95210, 95211, 95212, 95213, 95215, 95219, 95220, 95221, 95222, 95223, 95224, 95225, 95226, 95227, 95228, 95229, 95230, 95231, 95232, 95233, 95234, 95236, 95237, 95240, 95241, 95242, 95245, 95246, 95247, 95248, 95249, 95250,
          95251, 95252, 95253, 95254, 95255, 95257, 95258, 95267, 95269, 95296, 95297, 95301, 95303, 95304, 95305, 95306, 95309, 95310, 95311, 95312, 95313, 95314, 95315, 95316, 95317, 95318, 95320, 95321, 95322, 95324, 95325, 95327, 95329, 95330, 95333, 95334, 95335, 95336, 95337, 95338, 95340,
          95341, 95343, 95344, 95345, 95346, 95347, 95348, 95353, 95355, 95357, 95358, 95360, 95361, 95363, 95364, 95365, 95366, 95367, 95369, 95370, 95372, 95373, 95374, 95375, 95376, 95377, 95378, 95379, 95380, 95383, 95385, 95386, 95387, 95388, 95389, 95391, 95401, 95402, 95403, 95404, 95405,
          95406, 95407, 95409, 95410, 95412, 95415, 95416, 95417, 95418, 95419, 95420, 95421, 95422, 95423, 95424, 95425, 95426, 95427, 95428, 95429, 95430, 95431, 95432, 95433, 95435, 95436, 95437, 95439, 95441, 95442, 95443, 95444, 95445, 95446, 95448, 95449, 95450, 95451, 95452, 95453, 95454,
          95456, 95457, 95458, 95459, 95460, 95461, 95462, 95463, 95464, 95465, 95466, 95467, 95468, 95469, 95470, 95471, 95472, 95473, 95476, 95480, 95481, 95482, 95485, 95486, 95487, 95488, 95490, 95492, 95493, 95494, 95497, 95501, 95502, 95503, 95511, 95514, 95518, 95519, 95521, 95524, 95525,
          95526, 95527, 95528, 95534, 95536, 95537, 95540, 95542, 95545, 95546, 95547, 95549, 95550, 95551, 95552, 95553, 95554, 95555, 95556, 95558, 95559, 95560, 95562, 95563, 95564, 95565, 95568, 95569, 95570, 95571, 95573, 95585, 95587, 95589, 95595, 95601, 95602, 95603, 95604, 95605, 95606,
          95607, 95612, 95613, 95614, 95615, 95616, 95617, 95618, 95619, 95620, 95623, 95624, 95625, 95626, 95627, 95629, 95631, 95632, 95633, 95634, 95635, 95636, 95637, 95640, 95641, 95642, 95644, 95645, 95646, 95648, 95650, 95651, 95653, 95654, 95656, 95658, 95659, 95661, 95663, 95664, 95665,
          95666, 95667, 95668, 95669, 95672, 95674, 95675, 95676, 95677, 95678, 95679, 95680, 95681, 95682, 95684, 95685, 95686, 95687, 95688, 95689, 95690, 95691, 95692, 95694, 95695, 95696, 95697, 95698, 95699, 95701, 95703, 95709, 95712, 95713, 95714, 95715, 95717, 95720, 95721, 95722, 95724,
          95726, 95728, 95735, 95736, 95746, 95747, 95762, 95765, 95776, 95798, 95799, 95812, 95899, 95901, 95903, 95910, 95912, 95913, 95914, 95915, 95916, 95917, 95918, 95919, 95920, 95922, 95923, 95924, 95925, 95926, 95927, 95928, 95929, 95930, 95932, 95934, 95935, 95936, 95937, 95938, 95939,
          95940, 95941, 95942, 95943, 95944, 95945, 95946, 95947, 95948, 95949, 95950, 95951, 95953, 95954, 95955, 95956, 95957, 95958, 95959, 95960, 95961, 95962, 95963, 95965, 95966, 95967, 95968, 95969, 95970, 95971, 95972, 95973, 95974, 95975, 95976, 95977, 95978, 95979, 95980, 95981, 95982,
          95983, 95984, 95986, 95987, 95988, 95991, 95992, 95993, 96001, 96002, 96003, 96007, 96008, 96009, 96011, 96013, 96016, 96019, 96020, 96021, 96022, 96028, 96029, 96033, 96035, 96040, 96047, 96049, 96051, 96055, 96056, 96059, 96061, 96062, 96063, 96065, 96068, 96069, 96070, 96071, 96073,
          96074, 96075, 96076, 96078, 96080, 96084, 96087, 96088, 96089, 96090, 96092, 96095, 96096, 96099, 96120, 96124, 96125, 96137, 96155, 96162,
        ],
      },
      {
        provider: "SoCalREN",
        "home-energy-efficiency-web": "https://socalren.com/",
        assessments: "https://socalren.com/residential/homeowners/",
        heating: "https://socalren.com/residential/homeowners",
        "water-heater": "https://socalren.com/residential/homeowners",
        cooling: "https://socalren.com/residential/homeowners",
        insulation: "https://socalren.com/residential/homeowners",
        "air-sealing": "https://socalren.com/residential/homeowners",
        lighting: "https://socalren.com/residential/homeowners",
        "efficient-appliances-residental": "https://socalren.com/residential/homeowners",
        "efficient-appliances-small-business": "https://socalren.com/",
        solar: "https://socalren.com/residential/homeowners",
        programs: "https://socalren.com/",
        "default-hee": "https://socalren.com/",
        "the-movement": "https://socalren.com/",
        "energy-heroes-fabian-rodriguez": "https://socalren.com/",
        default: "https://socalren.com/",
        "home-improvements": "https://socalren.com/residential/homeowners",
        assistance: "https://socalren.com/residential/homeowners",
        "assess-your-energy": "https://socalren.com/residential/homeowners",
        "do-it-yourself": "https://socalren.com/residential/homeowners",
        contractor: "https://socalren.com/multifamily/contractors/participating-contractors",
        "water-heating": "https://socalren.com/residential/homeowners",
        appliances: "https://socalren.com/residential/homeowners",
        "energy-management-tools": "https://socalren.com/residential/homeowners",
        rebates: "https://socalren.com/residential/homeowners",
        financing: "https://socalren.com/residential/homeowners",
        esa: "https://www.socalren.org/multifamily/financing-info",
        zip: [
          89010, 89019, 90001, 90002, 90003, 90004, 90005, 90006, 90007, 90008, 90009, 90010, 90011, 90012, 90013, 90014, 90015, 90016, 90017, 90018, 90019, 90020, 90021, 90022, 90023, 90024, 90025, 90026, 90027, 90028, 90029, 90031, 90032, 90033, 90034, 90035, 90036, 90037, 90038, 90039, 90040,
          90041, 90042, 90043, 90044, 90045, 90046, 90047, 90048, 90049, 90052, 90056, 90057, 90058, 90059, 90061, 90062, 90063, 90064, 90065, 90066, 90067, 90068, 90069, 90071, 90073, 90077, 90079, 90089, 90090, 90094, 90201, 90210, 90211, 90212, 90220, 90221, 90222, 90230, 90232, 90240, 90241,
          90242, 90245, 90247, 90248, 90249, 90250, 90254, 90255, 90260, 90261, 90262, 90263, 90265, 90266, 90270, 90272, 90274, 90275, 90277, 90278, 90280, 90290, 90291, 90292, 90293, 90301, 90302, 90303, 90304, 90305, 90401, 90402, 90403, 90404, 90405, 90501, 90502, 90503, 90504, 90505, 90506,
          90601, 90602, 90603, 90604, 90605, 90606, 90620, 90621, 90622, 90623, 90630, 90631, 90638, 90639, 90640, 90650, 90660, 90670, 90680, 90701, 90703, 90704, 90706, 90710, 90712, 90713, 90714, 90715, 90716, 90717, 90720, 90723, 90731, 90732, 90740, 90742, 90743, 90744, 90745, 90746, 90747,
          90755, 90802, 90803, 90804, 90805, 90806, 90807, 90808, 90810, 90813, 90814, 90815, 90822, 90831, 90895, 91001, 91006, 91007, 91008, 91010, 91011, 91016, 91020, 91024, 91030, 91040, 91042, 91046, 91101, 91103, 91104, 91105, 91106, 91107, 91108, 91121, 91124, 91125, 91201, 91202, 91203,
          91204, 91205, 91206, 91207, 91208, 91210, 91214, 91301, 91302, 91303, 91304, 91306, 91307, 91310, 91311, 91316, 91320, 91321, 91324, 91325, 91326, 91329, 91330, 91331, 91335, 91340, 91342, 91343, 91344, 91345, 91350, 91351, 91352, 91354, 91355, 91356, 91360, 91361, 91362, 91364, 91367,
          91371, 91372, 91377, 91381, 91382, 91383, 91384, 91387, 91390, 91401, 91402, 91403, 91405, 91406, 91411, 91423, 91436, 91501, 91502, 91504, 91505, 91506, 91521, 91522, 91523, 91601, 91602, 91604, 91605, 91606, 91607, 91608, 91701, 91702, 91706, 91708, 91709, 91710, 91711, 91715, 91722,
          91723, 91724, 91730, 91731, 91732, 91733, 91734, 91737, 91739, 91740, 91741, 91743, 91744, 91745, 91746, 91747, 91748, 91750, 91752, 91754, 91755, 91759, 91761, 91762, 91763, 91764, 91765, 91766, 91767, 91768, 91769, 91770, 91773, 91775, 91776, 91778, 91780, 91784, 91785, 91786, 91788,
          91789, 91790, 91791, 91792, 91793, 91801, 91802, 92201, 92203, 92210, 92211, 92220, 92223, 92225, 92227, 92230, 92231, 92233, 92234, 92236, 92239, 92240, 92241, 92242, 92243, 92249, 92250, 92251, 92252, 92253, 92254, 92256, 92257, 92258, 92260, 92261, 92262, 92264, 92266, 92267, 92268,
          92270, 92273, 92274, 92275, 92276, 92277, 92278, 92280, 92281, 92282, 92283, 92284, 92285, 92301, 92304, 92305, 92307, 92308, 92309, 92310, 92311, 92313, 92314, 92315, 92316, 92317, 92318, 92320, 92321, 92322, 92323, 92324, 92325, 92327, 92328, 92332, 92333, 92335, 92336, 92337, 92338,
          92339, 92340, 92341, 92342, 92344, 92345, 92346, 92347, 92350, 92352, 92354, 92356, 92357, 92358, 92359, 92363, 92364, 92365, 92368, 92371, 92372, 92373, 92374, 92375, 92376, 92377, 92378, 92382, 92384, 92385, 92386, 92389, 92391, 92392, 92394, 92395, 92397, 92398, 92399, 92401, 92404,
          92405, 92407, 92408, 92410, 92411, 92414, 92415, 92418, 92423, 92424, 92501, 92503, 92504, 92505, 92506, 92507, 92508, 92509, 92518, 92522, 92530, 92532, 92543, 92544, 92545, 92548, 92549, 92551, 92553, 92555, 92557, 92561, 92563, 92567, 92570, 92571, 92581, 92582, 92583, 92584, 92585,
          92586, 92587, 92589, 92591, 92595, 92596, 92599, 92602, 92603, 92604, 92606, 92607, 92610, 92612, 92614, 92615, 92617, 92618, 92619, 92620, 92623, 92625, 92626, 92627, 92628, 92630, 92646, 92647, 92648, 92649, 92650, 92652, 92653, 92654, 92655, 92657, 92658, 92659, 92660, 92661, 92662,
          92663, 92674, 92676, 92678, 92683, 92684, 92690, 92697, 92701, 92702, 92703, 92704, 92705, 92706, 92707, 92708, 92711, 92728, 92780, 92781, 92782, 92799, 92801, 92802, 92803, 92804, 92805, 92806, 92807, 92808, 92809, 92812, 92814, 92815, 92816, 92817, 92821, 92822, 92823, 92831, 92832,
          92833, 92834, 92835, 92836, 92837, 92838, 92840, 92841, 92842, 92843, 92844, 92845, 92856, 92857, 92860, 92861, 92863, 92865, 92866, 92867, 92868, 92869, 92870, 92871, 92877, 92878, 92879, 92880, 92881, 92882, 92883, 92885, 92886, 92887, 92899, 93001, 93003, 93004, 93009, 93010, 93011,
          93012, 93013, 93015, 93020, 93021, 93022, 93030, 93033, 93035, 93036, 93040, 93041, 93043, 93060, 93063, 93064, 93065, 93066, 93067, 93101, 93103, 93105, 93108, 93109, 93110, 93111, 93117, 93199, 93202, 93205, 93207, 93208, 93216, 93218, 93221, 93223, 93227, 93235, 93238, 93240, 93247,
          93255, 93257, 93258, 93260, 93261, 93265, 93267, 93270, 93277, 93282, 93283, 93285, 93287, 93380, 93408, 93435, 93456, 93501, 93505, 93510, 93512, 93516, 93519, 93522, 93523, 93524, 93527, 93528, 93529, 93530, 93531, 93532, 93534, 93535, 93536, 93543, 93544, 93545, 93549, 93550, 93551,
          93552, 93553, 93554, 93555, 93558, 93560, 93561, 93562, 93563, 93591, 93592, 93599, 93605, 93607, 93666, 93670,
        ],
      },
      {
        provider: "San Diego Gas & Electric",
        "home-energy-efficiency-web": "https://www.sdge.com/residential",
        assessments: "https://www.sdge.com/save-money/solutions-your-home/your-home-can-save-you-money",
        heating: "https://www.sdge.com/residential/easy-and-affordable-ways-save/easy-ways-save",
        "water-heater": "https://www.sdge.com/residential/easy-and-affordable-ways-save/easy-ways-save",
        cooling: "https://www.sdge.com/residential/easy-and-affordable-ways-save/easy-ways-save",
        insulation: "https://www.sdge.com/rebates-finder/home-insulation",
        "air-sealing": "https://www.sdge.com/rebates-finder/home-insulation",
        lighting: "https://marketplace.sdge.com/",
        "efficient-appliances-residental": "https://marketplace.sdge.com/",
        "efficient-appliances-small-business": "https://marketplace.sdge.com/",
        solar: "https://www.sdge.com/environment/solar-overview",
        programs: "https://www.sdge.com/residential/available-assistance-programs",
        "default-hee": "https://www.sdge.com/residential",
        "the-movement": "https://www.sdge.com/residential",
        "energy-heroes-fabian-rodriguez": "https://marketplace.sdge.com/",
        default: "https://www.sdge.com/residential",
        assistance: "https://www.sdge.com/residential/available-assistance-programs",
        "financing-assistance": "https://www.sdge.com/save-money/financing",
        rebates: "https://www.sdge.com/residential/easy-and-affordable-ways-save/easy-ways-save",
        "do-it-yourself": "https://www.sdge.com/save-money/solutions-your-home/your-home-can-save-you-money",
        contractor: "https://www.sdge.com/find-trade-pro",
        "home-energy-efficiency/contractor": "https://www.sdge.com/find-trade-pro",
        "assess-your-energy": "https://www.sdge.com/myaccountvideo",
        appliances: "https://marketplace.sdge.com/",
        "energy-management-tools": "https://www.sdge.com/residential/sdge-marketplace",
        "home-improvements": "https://www.sdge.com/save-money/energy-upgrade-california-home-upgrade",
        "home-heating": "https://www.sdge.com/residential/easy-and-affordable-ways-save/easy-ways-save",
        "home-cooling-and-ac": "https://www.sdge.com/residential/easy-and-affordable-ways-save/easy-ways-save",
        "insulation-and-air-sealing": "https://www.sdge.com/rebates-finder/home-insulation",
        "solar-power-systems": "https://www.sdge.com/environment/solar-overview",
        financing: "https://www.sdge.com/save-money/financing",
        "time-of-use": "https://www.sdge.com/whenmatters",
        esa: "https://www.sdge.com/residential/pay-bill/get-payment-bill-assistance/assistance-programs/no-cost-energy-efficient-home-improvements",
        zip: [
          91901, 91902, 91903, 91905, 91906, 91908, 91909, 91910, 91911, 91912, 91913, 91914, 91915, 91916, 91917, 91921, 91931, 91932, 91933, 91934, 91935, 91941, 91942, 91943, 91944, 91945, 91946, 91947, 91948, 91950, 91951, 91962, 91963, 91970, 91976, 91977, 91978, 91979, 91980, 91987, 92003,
          92004, 92007, 92008, 92009, 92010, 92011, 92013, 92014, 92018, 92019, 92020, 92021, 92022, 92023, 92024, 92025, 92026, 92027, 92028, 92029, 92032, 92036, 92037, 92038, 92039, 92040, 92041, 92045, 92049, 92050, 92051, 92052, 92054, 92055, 92056, 92057, 92058, 92059, 92060, 92061, 92064,
          92065, 92066, 92067, 92068, 92069, 92070, 92071, 92072, 92074, 92075, 92078, 92079, 92081, 92082, 92083, 92084, 92085, 92086, 92088, 92091, 92092, 92093, 92096, 92101, 92102, 92103, 92104, 92105, 92106, 92107, 92108, 92109, 92110, 92111, 92112, 92113, 92114, 92115, 92116, 92117, 92118,
          92119, 92120, 92121, 92122, 92123, 92124, 92125, 92126, 92127, 92128, 92129, 92130, 92131, 92132, 92133, 92134, 92135, 92136, 92137, 92138, 92139, 92140, 92142, 92143, 92145, 92147, 92149, 92150, 92152, 92153, 92154, 92155, 92158, 92159, 92160, 92161, 92163, 92164, 92165, 92166, 92167,
          92168, 92169, 92170, 92171, 92172, 92173, 92174, 92175, 92176, 92177, 92178, 92179, 92182, 92186, 92187, 92190, 92191, 92192, 92193, 92195, 92196, 92197, 92198, 92199, 92536, 92607, 92624, 92625, 92629, 92649, 92651, 92653, 92654, 92656, 92672, 92673, 92674, 92675, 92676, 92677, 92679,
          92688, 92690, 92691, 92692, 92693, 92694,
        ],
      },
      {
        provider: "Southern California Edison",
        "home-energy-efficiency-web": "https://www.sce.com/residential/home-energy-guide/Home-Energy-Advisor",
        assessments: "https://www.sce.com/residential/home-energy-guide/Home-Energy-Advisor",
        heating: "https://www.sce.com/residential/home-energy-guide/Home-Energy-Advisor",
        "water-heater": "https://www.sce.com/residential/home-energy-guide/Home-Energy-Advisor",
        cooling: "https://www.sce.com/residential/home-energy-guide/Home-Energy-Advisor",
        insulation: "https://www.sce.com/residential/home-energy-guide/Home-Energy-Advisor",
        "air-sealing": "https://www.sce.com/residential/home-energy-guide/Home-Energy-Advisor",
        lighting: "https://www.sce.com/residential/home-energy-guide/Home-Energy-Advisor",
        "efficient-appliances-residental": "https://www.sce.com/residential/home-energy-guide/Home-Energy-Advisor",
        "efficient-appliances-small-business": "https://www.sce.com/residential/home-energy-guide/Home-Energy-Advisor",
        programs: "https://www.sce.com/residential/home-energy-guide/Home-Energy-Advisor",
        "default-hee": "https://www.sce.com/residential/home-energy-guide/Home-Energy-Advisor",
        "the-movement": "https://www.sce.com/residential/home-energy-guide/Home-Energy-Advisor",
        "energy-heroes-fabian-rodriguez": "https://www.sce.com/residential/home-energy-guide/Home-Energy-Advisor",
        default: "https://www.sce.com/residential/home-energy-guide/Home-Energy-Advisor/",
        assistance: "https://www.sce.com/residential/home-energy-guide/Home-Energy-Advisor",
        financing: "https://www.sce.com/residential/home-energy-guide/Home-Energy-Advisor",
        rebates: "https://www.sce.com/residential/home-energy-guide/Home-Energy-Advisor",
        "do-it-yourself": "https://www.sce.com/residential/home-energy-guide/Home-Energy-Advisor",
        "energy-management-tools": "https://www.sce.com/residential/home-energy-guide?utm_source=euc-hee-lighting&utm_medium=euc&utm_campaign=euc%20Referrals&&utm_source=EUC-HEE&utm_medium=Website",
        "assess-your-energy": "https://www.sce.com/residential/home-energy-guide/Home-Energy-Advisor",
        appliances: "https://www.sce.com/residential/home-energy-guide/Home-Energy-Advisor",
        "time-of-use": "https://www.sce.com/residential/home-energy-guide/Home-Energy-Advisor",
        "HOME ENERGY GUIDE": "https://www.sce.com/residential/home-energy-guide/Home-Energy-Advisor",
        "AIR CONDITIONING": "https://www.sce.com/residential/home-energy-guide/Home-Energy-Advisor",
        esa: "https://www.sce.com/residential/assistance/energy-saving-program",
        zip: [
          90001, 90002, 90022, 90038, 90040, 90044, 90046, 90048, 90051, 90059, 90061, 90063, 90069, 90091, 90096, 90201, 90202, 90209, 90211, 90212, 90220, 90221, 90222, 90223, 90224, 90239, 90240, 90241, 90242, 90245, 90247, 90248, 90249, 90250, 90251, 90254, 90255, 90260, 90261, 90262, 90263,
          90264, 90265, 90266, 90267, 90270, 90274, 90275, 90277, 90278, 90280, 90290, 90301, 90302, 90303, 90304, 90305, 90306, 90307, 90308, 90309, 90310, 90311, 90312, 90401, 90402, 90404, 90405, 90406, 90501, 90502, 90503, 90504, 90505, 90506, 90507, 90508, 90509, 90510, 90601, 90602, 90603,
          90604, 90605, 90606, 90607, 90608, 90609, 90610, 90620, 90621, 90622, 90623, 90624, 90630, 90631, 90632, 90633, 90637, 90638, 90639, 90640, 90650, 90651, 90652, 90660, 90661, 90662, 90670, 90671, 90680, 90701, 90702, 90703, 90704, 90706, 90707, 90711, 90712, 90713, 90714, 90715, 90716,
          90717, 90720, 90721, 90723, 90731, 90740, 90742, 90743, 90745, 90746, 90747, 90749, 90755, 90801, 90802, 90803, 90804, 90805, 90806, 90807, 90808, 90809, 90813, 90814, 90815, 90822, 90831, 90832, 90833, 90834, 90835, 90840, 90842, 90844, 90846, 90847, 90848, 90853, 90895, 90899, 91001,
          91003, 91006, 91007, 91008, 91009, 91010, 91011, 91016, 91017, 91023, 91024, 91025, 91030, 91031, 91040, 91042, 91066, 91077, 91108, 91118, 91301, 91302, 91304, 91307, 91310, 91319, 91320, 91321, 91322, 91342, 91350, 91351, 91354, 91355, 91358, 91359, 91360, 91361, 91362, 91372, 91376,
          91377, 91380, 91381, 91382, 91383, 91384, 91385, 91386, 91387, 91390, 91608, 91701, 91702, 91706, 91708, 91709, 91710, 91711, 91714, 91715, 91716, 91722, 91723, 91724, 91729, 91730, 91731, 91732, 91733, 91734, 91735, 91737, 91739, 91740, 91741, 91743, 91744, 91745, 91746, 91747, 91748,
          91749, 91750, 91752, 91754, 91755, 91756, 91758, 91759, 91761, 91762, 91763, 91764, 91765, 91766, 91767, 91768, 91769, 91770, 91771, 91772, 91773, 91775, 91776, 91778, 91780, 91784, 91785, 91786, 91788, 91789, 91790, 91791, 91792, 91793, 91795, 91801, 91802, 91803, 91804, 91896, 91899,
          92223, 92225, 92226, 92230, 92234, 92235, 92239, 92240, 92242, 92252, 92255, 92256, 92258, 92260, 92261, 92262, 92263, 92264, 92267, 92268, 92270, 92277, 92278, 92280, 92282, 92284, 92285, 92286, 92301, 92304, 92305, 92307, 92308, 92309, 92310, 92311, 92312, 92313, 92314, 92316, 92317,
          92318, 92320, 92321, 92322, 92323, 92325, 92326, 92327, 92328, 92329, 92331, 92332, 92334, 92335, 92336, 92337, 92338, 92339, 92340, 92341, 92342, 92344, 92345, 92346, 92347, 92350, 92352, 92354, 92356, 92357, 92358, 92359, 92364, 92365, 92366, 92368, 92369, 92371, 92372, 92373, 92374,
          92375, 92376, 92377, 92378, 92382, 92384, 92385, 92389, 92391, 92392, 92393, 92394, 92395, 92397, 92398, 92399, 92401, 92402, 92403, 92404, 92405, 92406, 92407, 92408, 92410, 92411, 92412, 92413, 92414, 92415, 92418, 92423, 92427, 92503, 92509, 92518, 92519, 92530, 92531, 92532, 92543,
          92544, 92545, 92546, 92548, 92549, 92551, 92552, 92553, 92555, 92556, 92557, 92562, 92563, 92564, 92567, 92570, 92571, 92572, 92581, 92582, 92583, 92584, 92585, 92586, 92587, 92589, 92590, 92591, 92592, 92593, 92595, 92596, 92599, 92602, 92603, 92604, 92605, 92606, 92609, 92610, 92612,
          92614, 92615, 92616, 92617, 92618, 92619, 92620, 92623, 92625, 92626, 92627, 92628, 92630, 92637, 92646, 92647, 92648, 92649, 92650, 92651, 92652, 92654, 92655, 92656, 92657, 92658, 92659, 92660, 92661, 92662, 92663, 92676, 92678, 92683, 92684, 92685, 92688, 92692, 92697, 92698, 92701,
          92702, 92703, 92704, 92705, 92706, 92707, 92708, 92711, 92712, 92728, 92735, 92780, 92781, 92782, 92799, 92804, 92808, 92811, 92821, 92822, 92823, 92831, 92832, 92833, 92834, 92835, 92836, 92837, 92838, 92840, 92841, 92842, 92843, 92844, 92845, 92846, 92856, 92857, 92859, 92860, 92861,
          92862, 92863, 92864, 92865, 92866, 92867, 92868, 92869, 92870, 92871, 92877, 92878, 92879, 92880, 92881, 92882, 92883, 92885, 92886, 92887, 93001, 93002, 93003, 93004, 93005, 93006, 93007, 93009, 93010, 93011, 93012, 93013, 93014, 93015, 93016, 93020, 93021, 93022, 93023, 93024, 93030,
          93031, 93032, 93033, 93034, 93035, 93036, 93040, 93041, 93042, 93043, 93044, 93060, 93061, 93062, 93063, 93064, 93065, 93066, 93067, 93094, 93099, 93101, 93102, 93103, 93105, 93106, 93107, 93108, 93109, 93110, 93111, 93116, 93117, 93118, 93120, 93121, 93130, 93140, 93150, 93160, 93190,
          93199, 93202, 93205, 93207, 93208, 93215, 93216, 93218, 93220, 93221, 93222, 93223, 93225, 93226, 93227, 93230, 93232, 93235, 93237, 93238, 93240, 93243, 93244, 93247, 93250, 93252, 93255, 93256, 93257, 93258, 93260, 93261, 93262, 93265, 93267, 93270, 93271, 93272, 93274, 93275, 93277,
          93278, 93279, 93283, 93285, 93286, 93287, 93290, 93291, 93292, 93301, 93306, 93501, 93502, 93504, 93505, 93510, 93512, 93513, 93514, 93515, 93516, 93517, 93518, 93519, 93522, 93523, 93524, 93527, 93528, 93529, 93530, 93531, 93532, 93534, 93535, 93536, 93539, 93541, 93542, 93543, 93544,
          93545, 93546, 93549, 93550, 93551, 93552, 93553, 93554, 93555, 93556, 93558, 93560, 93561, 93562, 93563, 93581, 93584, 93586, 93590, 93591, 93592, 93596, 93599, 93605, 93634, 93642, 93670,
        ],
      },
      {
        provider: "SoCalGas",
        "home-energy-efficiency-web": "https://www.socalgas.com",
        assessments: "https://www.socalgas.com/save-money-and-energy/energy-saving-tips-tools/ways-to-save",
        heating: "https://www.socalgas.com/save-money-and-energy/rebates-and-incentives/natural-gas-appliance-rebates",
        "water-heater": "https://www.socalgas.com/save-money-and-energy/rebates-and-incentives/natural-gas-appliance-rebates",
        cooling: "https://www.socalgas.com/smart-energy/technology-and-investments/natural-gas-air-conditioning",
        insulation: "https://www.socalgas.com/save-money-and-energy/rebates-and-incentives/natural-gas-appliance-rebates",
        "air-sealing": "https://www.socalgas.com/save-money-and-energy/rebates-and-incentives/natural-gas-appliance-rebates",
        lighting: "https://www.socalgas.com",
        "efficient-appliances-residental": "https://www.socalgas.com/save-money-and-energy/rebates-and-incentives/natural-gas-appliance-rebates",
        "efficient-appliances-small-business": "https://www.socalgas.com/save-money-and-energy/rebates-and-incentives/natural-gas-appliance-rebates",
        solar: "https://www.socalgas.com/save-money-and-energy/solar-water-heating",
        programs: "https://www.socalgas.com/save-money-and-energy/assistance-programs",
        "default-hee": "https://www.socalgas.com",
        "the-movement": "https://www.socalgas.com",
        "energy-heroes-fabian-rodriguez": "https://www.socalgas.com",
        default: "https://www.socalgas.com",
        rebates: "https://www.socalgas.com/save-money-and-energy/rebates-and-incentives",
        assistance: "https://www.socalgas.com/save-money-and-energy/assistance-programs",
        contractor: "https://www.socalgas.com/save-money-and-energy/energy-saving-tips-tools/hiring-a-contractor",
        "financing-assistance": "https://www.socalgas.com/save-money-and-energy/assistance-programs",
        "do-it-yourself": "https://www.socalgas.com/save-money-and-energy/energy-saving-tips-tools",
        "energy-management-tools": "https://www.socalgas.com/save-money-and-energy/rebates-and-incentives/natural-gas-appliance-rebates",
        "home-improvements": "https://www.socalgas.com/save-money-and-energy/rebates-and-incentives/energy-upgrade-california",
        "assess-your-energy": "https://www.socalgas.com/home",
        financing: "https://www.socalgas.com/for-your-business/energy-savings/financing",
        appliances: "https://www.socalgas.com/save-money-and-energy/rebates-and-incentives/natural-gas-appliance-rebates",
        "water-heating": "https://www.socalgas.com/save-money-and-energy/rebates-and-incentives/natural-gas-appliance-rebates",
        esa: "https://www.socalgas.com/save-money-and-energy/assistance-programs/energy-savings-assistance-program",
        zip: [
          90001, 90002, 90003, 90004, 90005, 90006, 90007, 90008, 90009, 90010, 90011, 90012, 90013, 90014, 90015, 90016, 90017, 90018, 90019, 90020, 90021, 90022, 90023, 90024, 90025, 90026, 90027, 90028, 90029, 90031, 90032, 90033, 90034, 90035, 90036, 90037, 90038, 90039, 90040, 90041, 90042,
          90043, 90044, 90045, 90046, 90047, 90048, 90049, 90052, 90056, 90057, 90058, 90059, 90061, 90062, 90063, 90064, 90065, 90066, 90067, 90068, 90069, 90071, 90073, 90077, 90079, 90089, 90090, 90094, 90095, 90201, 90210, 90211, 90212, 90220, 90221, 90222, 90230, 90232, 90240, 90241, 90242,
          90245, 90247, 90248, 90249, 90250, 90254, 90255, 90260, 90261, 90262, 90263, 90265, 90266, 90270, 90272, 90274, 90275, 90277, 90278, 90280, 90290, 90291, 90292, 90293, 90301, 90302, 90303, 90304, 90305, 90401, 90402, 90403, 90404, 90405, 90501, 90502, 90503, 90504, 90505, 90506, 90601,
          90602, 90603, 90604, 90605, 90606, 90620, 90621, 90622, 90623, 90630, 90631, 90638, 90639, 90640, 90650, 90660, 90670, 90680, 90701, 90703, 90706, 90710, 90712, 90713, 90714, 90715, 90716, 90717, 90720, 90723, 90731, 90732, 90740, 90742, 90743, 90744, 90745, 90746, 90747, 90755, 90802,
          90803, 90804, 90805, 90806, 90807, 90808, 90810, 90895, 91001, 91006, 91007, 91008, 91010, 91011, 91016, 91020, 91024, 91030, 91040, 91042, 91046, 91101, 91103, 91104, 91105, 91106, 91107, 91108, 91121, 91124, 91125, 91170, 91201, 91202, 91203, 91204, 91205, 91206, 91207, 91208, 91210,
          91214, 91301, 91302, 91303, 91304, 91306, 91307, 91310, 91311, 91316, 91320, 91321, 91324, 91325, 91326, 91329, 91330, 91331, 91335, 91340, 91342, 91343, 91344, 91345, 91348, 91350, 91351, 91352, 91354, 91355, 91356, 91360, 91361, 91362, 91364, 91367, 91371, 91372, 91377, 91381, 91382,
          91383, 91384, 91387, 91390, 91401, 91402, 91403, 91405, 91406, 91411, 91423, 91436, 91501, 91502, 91504, 91505, 91506, 91521, 91522, 91523, 91601, 91602, 91604, 91605, 91606, 91607, 91608, 91701, 91702, 91706, 91708, 91709, 91710, 91711, 91715, 91719, 91722, 91723, 91724, 91730, 91731,
          91732, 91733, 91734, 91737, 91739, 91740, 91741, 91743, 91744, 91745, 91746, 91747, 91748, 91750, 91752, 91754, 91755, 91761, 91762, 91763, 91764, 91765, 91766, 91767, 91768, 91769, 91770, 91773, 91775, 91776, 91778, 91780, 91784, 91785, 91786, 91788, 91789, 91790, 91791, 91792, 91793,
          91801, 91802, 91803, 92173, 92201, 92203, 92210, 92211, 92220, 92223, 92225, 92227, 92230, 92231, 92233, 92234, 92236, 92239, 92240, 92241, 92243, 92249, 92250, 92251, 92252, 92253, 92254, 92256, 92257, 92258, 92260, 92261, 92262, 92264, 92267, 92270, 92273, 92274, 92275, 92276, 92277,
          92281, 92284, 92301, 92307, 92308, 92313, 92316, 92317, 92318, 92320, 92321, 92322, 92323, 92324, 92325, 92327, 92335, 92336, 92337, 92340, 92341, 92344, 92345, 92346, 92350, 92352, 92354, 92356, 92357, 92359, 92365, 92371, 92372, 92373, 92374, 92375, 92376, 92377, 92378, 92382, 92383,
          92385, 92386, 92391, 92392, 92397, 92399, 92401, 92404, 92405, 92407, 92408, 92410, 92411, 92414, 92415, 92418, 92423, 92424, 92501, 92503, 92504, 92505, 92506, 92507, 92508, 92509, 92518, 92522, 92530, 92531, 92532, 92543, 92544, 92545, 92548, 92551, 92553, 92555, 92557, 92562, 92563,
          92567, 92570, 92571, 92581, 92582, 92583, 92584, 92585, 92586, 92587, 92589, 92590, 92591, 92592, 92595, 92596, 92599, 92602, 92603, 92604, 92606, 92607, 92610, 92612, 92614, 92615, 92617, 92618, 92619, 92620, 92623, 92624, 92625, 92626, 92627, 92628, 92629, 92630, 92637, 92646, 92647,
          92648, 92649, 92650, 92651, 92652, 92653, 92654, 92655, 92656, 92657, 92658, 92659, 92660, 92661, 92662, 92663, 92667, 92672, 92673, 92674, 92675, 92676, 92677, 92678, 92679, 92683, 92684, 92688, 92690, 92691, 92692, 92694, 92697, 92701, 92702, 92703, 92704, 92705, 92706, 92707, 92708,
          92711, 92728, 92770, 92780, 92781, 92782, 92799, 92801, 92802, 92803, 92804, 92805, 92806, 92807, 92808, 92809, 92812, 92814, 92815, 92816, 92817, 92821, 92822, 92823, 92831, 92832, 92833, 92834, 92835, 92836, 92837, 92838, 92840, 92841, 92842, 92843, 92844, 92845, 92856, 92857, 92860,
          92861, 92863, 92865, 92866, 92867, 92868, 92869, 92870, 92871, 92877, 92878, 92879, 92880, 92881, 92882, 92883, 92885, 92886, 92887, 92899, 93001, 93003, 93004, 93009, 93010, 93011, 93012, 93013, 93015, 93020, 93021, 93022, 93023, 93030, 93033, 93035, 93036, 93040, 93041, 93043, 93060,
          93063, 93064, 93065, 93066, 93067, 93101, 93103, 93105, 93108, 93109, 93110, 93111, 93117, 93199, 93201, 93202, 93203, 93204, 93206, 93212, 93215, 93216, 93219, 93221, 93223, 93224, 93225, 93226, 93227, 93230, 93235, 93241, 93242, 93243, 93245, 93247, 93249, 93250, 93251, 93252, 93254,
          93255, 93256, 93257, 93261, 93263, 93265, 93266, 93267, 93268, 93270, 93272, 93274, 93276, 93277, 93280, 93282, 93286, 93291, 93292, 93301, 93304, 93307, 93308, 93309, 93311, 93312, 93313, 93314, 93380, 93401, 93402, 93405, 93408, 93410, 93420, 93422, 93424, 93427, 93428, 93430, 93433,
          93434, 93435, 93436, 93437, 93440, 93441, 93442, 93444, 93445, 93446, 93449, 93451, 93452, 93453, 93454, 93455, 93456, 93458, 93460, 93463, 93465, 93501, 93505, 93510, 93532, 93534, 93535, 93536, 93543, 93544, 93550, 93551, 93552, 93560, 93561, 93591, 93599, 93607, 93609, 93615, 93616,
          93618, 93625, 93630, 93631, 93646, 93647, 93648, 93652, 93654, 93656, 93657, 93660, 93662, 93666, 93670, 93673, 93706, 93725, 94040, 95501, 95521,
        ],
      },
      {
        provider: "Pacific Power",
        "home-energy-efficiency-web": "https://www.pacificpower.net/",
        assessments: "https://www.pacificpower.net/",
        heating: "https://www.pacificpower.net/",
        "water-heater": "https://www.pacificpower.net/",
        cooling: "https://www.pacificpower.net/",
        insulation: "https://www.pacificpower.net/",
        "air-sealing": "https://www.pacificpower.net/",
        lighting: "https://www.pacificpower.net/",
        "efficient-appliances-residental": "https://www.pacificpower.net/",
        "efficient-appliances-small-business": "https://www.pacificpower.net/",
        solar: "https://www.pacificpower.net/",
        programs: "https://www.pacificpower.net/",
        "default-hee": "https://www.pacificpower.net/",
        "the-movement": "https://www.pacificpower.net/",
        "energy-heroes-fabian-rodriguez": "https://www.pacificpower.net/",
        default: "https://www.pacificpower.net/",
        esa: "https://www.pacificpower.net/my-account/payments/bill-payment-assistance.html",
        zip: [95531, 95532, 95538, 95543, 95548, 95567, 95568, 96011, 96014, 96015, 96017, 96023, 96025, 96027, 96031, 96032, 96034, 96037, 96038, 96039, 96044, 96049, 96050, 96051, 96057, 96058, 96064, 96067, 96084, 96085, 96086, 96094, 96097, 96101, 96104, 96108, 96112, 96134],
      },
      {
        provider: "Anza Electric Coop Inc",
        "home-energy-efficiency-web": "https://www.anzaelectric.org/",
        assessments: "https://www.anzaelectric.org/",
        heating: "https://www.anzaelectric.org/",
        "water-heater": "https://www.anzaelectric.org/",
        cooling: "https://www.anzaelectric.org/",
        insulation: "https://www.anzaelectric.org/",
        "air-sealing": "https://www.anzaelectric.org/",
        lighting: "https://www.anzaelectric.org/",
        "efficient-appliances-residental": "https://www.anzaelectric.org/",
        "efficient-appliances-small-business": "https://www.anzaelectric.org/",
        solar: "https://www.anzaelectric.org/",
        programs: "https://www.anzaelectric.org/",
        "default-hee": "https://www.anzaelectric.org/",
        "the-movement": "https://www.anzaelectric.org/",
        "energy-heroes-fabian-rodriguez": "https://www.anzaelectric.org/",
        esa: "https://www.anzaelectric.org/assistance-utility",
        default: "https://www.anzaelectric.org/",
        zip: [92086],
      },
      {
        provider: "City & County of San Francisco",
        "home-energy-efficiency-web": "https://www.sfpuc.org/",
        assessments: "https://www.sfpuc.org/",
        heating: "https://www.sfpuc.org/",
        "water-heater": "https://www.sfpuc.org/",
        cooling: "https://www.sfpuc.org/",
        insulation: "https://www.sfpuc.org/",
        "air-sealing": "https://www.sfpuc.org/",
        lighting: "https://www.sfpuc.org/",
        "efficient-appliances-residental": "https://www.sfpuc.org/",
        "efficient-appliances-small-business": "https://www.sfpuc.org/",
        solar: "https://www.sfpuc.org/",
        programs: "https://www.sfpuc.org/",
        "default-hee": "https://www.sfpuc.org/",
        "the-movement": "https://www.sfpuc.org/",
        "energy-heroes-fabian-rodriguez": "https://www.sfpuc.org/",
        esa: "https://www.csd.ca.gov/Pages/LIHEAPProgram.aspx",
        default: "https://www.sfpuc.org/",
        zip: [94005],
      },
      {
        provider: "City of Alameda",
        "home-energy-efficiency-web": "http://www.alamedamp.com/",
        assessments: "http://www.alamedamp.com/",
        heating: "http://www.alamedamp.com/",
        "water-heater": "http://www.alamedamp.com/",
        cooling: "http://www.alamedamp.com/",
        insulation: "http://www.alamedamp.com/",
        "air-sealing": "http://www.alamedamp.com/",
        lighting: "http://www.alamedamp.com/",
        "efficient-appliances-residental": "http://www.alamedamp.com/",
        "efficient-appliances-small-business": "http://www.alamedamp.com/",
        solar: "http://www.alamedamp.com/",
        programs: "http://www.alamedamp.com/",
        "default-hee": "http://www.alamedamp.com/",
        "the-movement": "http://www.alamedamp.com/",
        "energy-heroes-fabian-rodriguez": "http://www.alamedamp.com/",
        esa: "https://www.alamedamp.com/166/COVID-19-Financial-Assistance-EAP",
        default: "http://www.alamedamp.com/",
        zip: [94130],
      },
      {
        provider: "City of Anaheim - (CA)",
        "home-energy-efficiency-web": "http://www.anaheim.net/",
        assessments: "http://www.anaheim.net/",
        heating: "http://www.anaheim.net/",
        "water-heater": "http://www.anaheim.net/",
        cooling: "http://www.anaheim.net/",
        insulation: "http://www.anaheim.net/",
        "air-sealing": "http://www.anaheim.net/",
        lighting: "http://www.anaheim.net/",
        "efficient-appliances-residental": "http://www.anaheim.net/",
        "efficient-appliances-small-business": "http://www.anaheim.net/",
        solar: "http://www.anaheim.net/",
        programs: "http://www.anaheim.net/",
        "default-hee": "http://www.anaheim.net/",
        "the-movement": "http://www.anaheim.net/",
        "energy-heroes-fabian-rodriguez": "http://www.anaheim.net/",
        esa: "http://www.anaheim.net/946/Weatherization-Program",
        default: "http://www.anaheim.net/",
        zip: [90620],
      },
      {
        provider: "City of Azusa",
        "home-energy-efficiency-web": "http://www.ci.azusa.ca.us/",
        assessments: "http://www.ci.azusa.ca.us/",
        heating: "http://www.ci.azusa.ca.us/",
        "water-heater": "http://www.ci.azusa.ca.us/",
        cooling: "http://www.ci.azusa.ca.us/",
        insulation: "http://www.ci.azusa.ca.us/",
        "air-sealing": "http://www.ci.azusa.ca.us/",
        lighting: "http://www.ci.azusa.ca.us/",
        "efficient-appliances-residental": "http://www.ci.azusa.ca.us/",
        "efficient-appliances-small-business": "http://www.ci.azusa.ca.us/",
        solar: "http://www.ci.azusa.ca.us/",
        programs: "http://www.ci.azusa.ca.us/",
        "default-hee": "http://www.ci.azusa.ca.us/",
        "the-movement": "http://www.ci.azusa.ca.us/",
        "energy-heroes-fabian-rodriguez": "http://www.ci.azusa.ca.us/",
        esa: "https://www.ci.azusa.ca.us/1914/Third-Party-Assistance-Programs",
        default: "http://www.ci.azusa.ca.us/",
        zip: [91010],
      },
      {
        provider: "City of Banning - (CA)",
        "home-energy-efficiency-web": "http://www.ci.banning.ca.us/",
        assessments: "http://www.ci.banning.ca.us/",
        heating: "http://www.ci.banning.ca.us/",
        "water-heater": "http://www.ci.banning.ca.us/",
        cooling: "http://www.ci.banning.ca.us/",
        insulation: "http://www.ci.banning.ca.us/",
        "air-sealing": "http://www.ci.banning.ca.us/",
        lighting: "http://www.ci.banning.ca.us/",
        "efficient-appliances-residental": "http://www.ci.banning.ca.us/",
        "efficient-appliances-small-business": "http://www.ci.banning.ca.us/",
        solar: "http://www.ci.banning.ca.us/",
        programs: "http://www.ci.banning.ca.us/",
        "default-hee": "http://www.ci.banning.ca.us/",
        "the-movement": "http://www.ci.banning.ca.us/",
        "energy-heroes-fabian-rodriguez": "http://www.ci.banning.ca.us/",
        esa: "http://www.ci.banning.ca.us/302/Low-Income-Assistance-BEAR",
        default: "http://www.ci.banning.ca.us/",
        zip: [92220],
      },
      {
        provider: "City of Burbank Water and Power",
        "home-energy-efficiency-web": "https://www.burbankwaterandpower.com/",
        assessments: "https://www.burbankwaterandpower.com/",
        heating: "https://www.burbankwaterandpower.com/",
        "water-heater": "https://www.burbankwaterandpower.com/",
        cooling: "https://www.burbankwaterandpower.com/",
        insulation: "https://www.burbankwaterandpower.com/",
        "air-sealing": "https://www.burbankwaterandpower.com/",
        lighting: "https://www.burbankwaterandpower.com/",
        "efficient-appliances-residental": "https://www.burbankwaterandpower.com/",
        "efficient-appliances-small-business": "https://www.burbankwaterandpower.com/",
        solar: "https://www.burbankwaterandpower.com/",
        programs: "https://www.burbankwaterandpower.com/",
        "default-hee": "https://www.burbankwaterandpower.com/",
        "the-movement": "https://www.burbankwaterandpower.com/",
        "energy-heroes-fabian-rodriguez": "https://www.burbankwaterandpower.com/",
        esa: "https://www.burbankwaterandpower.com/liheap",
        default: "https://www.burbankwaterandpower.com/",
        zip: [90027],
      },
      {
        provider: "City of Colton - (CA)",
        "home-energy-efficiency-web": "http://www.ci.colton.ca.us/",
        assessments: "http://www.ci.colton.ca.us/",
        heating: "http://www.ci.colton.ca.us/",
        "water-heater": "http://www.ci.colton.ca.us/",
        cooling: "http://www.ci.colton.ca.us/",
        insulation: "http://www.ci.colton.ca.us/",
        "air-sealing": "http://www.ci.colton.ca.us/",
        lighting: "http://www.ci.colton.ca.us/",
        "efficient-appliances-residental": "http://www.ci.colton.ca.us/",
        "efficient-appliances-small-business": "http://www.ci.colton.ca.us/",
        solar: "http://www.ci.colton.ca.us/",
        programs: "http://www.ci.colton.ca.us/",
        "default-hee": "http://www.ci.colton.ca.us/",
        "the-movement": "http://www.ci.colton.ca.us/",
        "energy-heroes-fabian-rodriguez": "http://www.ci.colton.ca.us/",
        esa: "https://www.ci.colton.ca.us/351/Low-Income-Assistance#:~:text=The%20City%20of%20Colton%20Electric,the%20first%20tier%20level%20charges.&text=This%20brings%20the%20tier%20from,from%20the%20date%20of%20approval",
        default: "http://www.ci.colton.ca.us/",
        zip: [92313],
      },
      {
        provider: "City of Corona - (CA)",
        "home-energy-efficiency-web": "http://discovercoronadwp.com/",
        assessments: "http://discovercoronadwp.com/",
        heating: "http://discovercoronadwp.com/",
        "water-heater": "http://discovercoronadwp.com/",
        cooling: "http://discovercoronadwp.com/",
        insulation: "http://discovercoronadwp.com/",
        "air-sealing": "http://discovercoronadwp.com/",
        lighting: "http://discovercoronadwp.com/",
        "efficient-appliances-residental": "http://discovercoronadwp.com/",
        "efficient-appliances-small-business": "http://discovercoronadwp.com/",
        solar: "http://discovercoronadwp.com/",
        programs: "http://discovercoronadwp.com/",
        "default-hee": "http://discovercoronadwp.com/",
        "the-movement": "http://discovercoronadwp.com/",
        "energy-heroes-fabian-rodriguez": "http://discovercoronadwp.com/",
        default: "http://discovercoronadwp.com/",
        zip: [92503],
      },
      {
        provider: "City of Escondido - (CA)",
        "home-energy-efficiency-web": "https://www.escondido.org/utilities.aspx",
        assessments: "https://www.escondido.org/utilities.aspx",
        heating: "https://www.escondido.org/utilities.aspx",
        "water-heater": "https://www.escondido.org/utilities.aspx",
        cooling: "https://www.escondido.org/utilities.aspx",
        insulation: "https://www.escondido.org/utilities.aspx",
        "air-sealing": "https://www.escondido.org/utilities.aspx",
        lighting: "https://www.escondido.org/utilities.aspx",
        "efficient-appliances-residental": "https://www.escondido.org/utilities.aspx",
        "efficient-appliances-small-business": "https://www.escondido.org/utilities.aspx",
        solar: "https://www.escondido.org/utilities.aspx",
        programs: "https://www.escondido.org/utilities.aspx",
        "default-hee": "https://www.escondido.org/utilities.aspx",
        "the-movement": "https://www.escondido.org/utilities.aspx",
        "energy-heroes-fabian-rodriguez": "https://www.escondido.org/utilities.aspx",
        esa: "https://www.escondido.org/utilities",
        default: "https://www.escondido.org/utilities.aspx",
        zip: [92025],
      },
      {
        provider: "City of Glendale - (CA)",
        "home-energy-efficiency-web": "http://www.glendaleca.gov/government/departments/glendale-water-and-power",
        assessments: "http://www.glendaleca.gov/government/departments/glendale-water-and-power",
        heating: "http://www.glendaleca.gov/government/departments/glendale-water-and-power",
        "water-heater": "http://www.glendaleca.gov/government/departments/glendale-water-and-power",
        cooling: "http://www.glendaleca.gov/government/departments/glendale-water-and-power",
        insulation: "http://www.glendaleca.gov/government/departments/glendale-water-and-power",
        "air-sealing": "http://www.glendaleca.gov/government/departments/glendale-water-and-power",
        lighting: "http://www.glendaleca.gov/government/departments/glendale-water-and-power",
        "efficient-appliances-residental": "http://www.glendaleca.gov/government/departments/glendale-water-and-power",
        "efficient-appliances-small-business": "http://www.glendaleca.gov/government/departments/glendale-water-and-power",
        solar: "http://www.glendaleca.gov/government/departments/glendale-water-and-power",
        programs: "http://www.glendaleca.gov/government/departments/glendale-water-and-power",
        "default-hee": "http://www.glendaleca.gov/government/departments/glendale-water-and-power",
        "the-movement": "http://www.glendaleca.gov/government/departments/glendale-water-and-power",
        "energy-heroes-fabian-rodriguez": "http://www.glendaleca.gov/government/departments/glendale-water-and-power",
        esa: "https://www.glendaleca.gov/government/departments/glendale-water-and-power/covid-19-response/financial-assistance-programs",
        default: "http://www.glendaleca.gov/government/departments/glendale-water-and-power",
        zip: [90027],
      },
      {
        provider: "City of Healdsburg - (CA)",
        "home-energy-efficiency-web": "http://www.ci.healdsburg.ca.us/",
        assessments: "http://www.ci.healdsburg.ca.us/",
        heating: "http://www.ci.healdsburg.ca.us/",
        "water-heater": "http://www.ci.healdsburg.ca.us/",
        cooling: "http://www.ci.healdsburg.ca.us/",
        insulation: "http://www.ci.healdsburg.ca.us/",
        "air-sealing": "http://www.ci.healdsburg.ca.us/",
        lighting: "http://www.ci.healdsburg.ca.us/",
        "efficient-appliances-residental": "http://www.ci.healdsburg.ca.us/",
        "efficient-appliances-small-business": "http://www.ci.healdsburg.ca.us/",
        solar: "http://www.ci.healdsburg.ca.us/",
        programs: "http://www.ci.healdsburg.ca.us/",
        "default-hee": "http://www.ci.healdsburg.ca.us/",
        "the-movement": "http://www.ci.healdsburg.ca.us/",
        "energy-heroes-fabian-rodriguez": "http://www.ci.healdsburg.ca.us/",
        esa: "https://www.ci.healdsburg.ca.us/676/Utility-Discounts",
        default: "http://www.ci.healdsburg.ca.us/",
        zip: [95448],
      },
      {
        provider: "City of Lodi - (CA)",
        "home-energy-efficiency-web": "http://www.lodielectric.com/",
        assessments: "http://www.lodielectric.com/",
        heating: "http://www.lodielectric.com/",
        "water-heater": "http://www.lodielectric.com/",
        cooling: "http://www.lodielectric.com/",
        insulation: "http://www.lodielectric.com/",
        "air-sealing": "http://www.lodielectric.com/",
        lighting: "http://www.lodielectric.com/",
        "efficient-appliances-residental": "http://www.lodielectric.com/",
        "efficient-appliances-small-business": "http://www.lodielectric.com/",
        solar: "http://www.lodielectric.com/",
        programs: "http://www.lodielectric.com/",
        "default-hee": "http://www.lodielectric.com/",
        "the-movement": "http://www.lodielectric.com/",
        "energy-heroes-fabian-rodriguez": "http://www.lodielectric.com/",
        esa: "http://lodielectric.com/911/Residential-Energy-Assistance-Programs",
        default: "http://www.lodielectric.com/",
        zip: [95220],
      },
      {
        provider: "City of Lompoc - (CA)",
        "home-energy-efficiency-web": "https://www.cityoflompoc.com/government/departments/utilities/electric",
        assessments: "https://www.cityoflompoc.com/government/departments/utilities/electric",
        heating: "https://www.cityoflompoc.com/government/departments/utilities/electric",
        "water-heater": "https://www.cityoflompoc.com/government/departments/utilities/electric",
        cooling: "https://www.cityoflompoc.com/government/departments/utilities/electric",
        insulation: "https://www.cityoflompoc.com/government/departments/utilities/electric",
        "air-sealing": "https://www.cityoflompoc.com/government/departments/utilities/electric",
        lighting: "https://www.cityoflompoc.com/government/departments/utilities/electric",
        "efficient-appliances-residental": "https://www.cityoflompoc.com/government/departments/utilities/electric",
        "efficient-appliances-small-business": "https://www.cityoflompoc.com/government/departments/utilities/electric",
        solar: "https://www.cityoflompoc.com/government/departments/utilities/electric",
        programs: "https://www.cityoflompoc.com/government/departments/utilities/electric",
        "default-hee": "https://www.cityoflompoc.com/government/departments/utilities/electric",
        "the-movement": "https://www.cityoflompoc.com/government/departments/utilities/electric",
        "energy-heroes-fabian-rodriguez": "https://www.cityoflompoc.com/government/departments/utilities/electric",
        esa: "https://www.cityoflompoc.com/government/departments/utilities/conservation",
        default: "https://www.cityoflompoc.com/government/departments/utilities/electric",
        zip: [93436],
      },
      {
        provider: "City of Moreno Valley - (CA)",
        "home-energy-efficiency-web": "http://www.moval.org/mvu/index.html",
        assessments: "http://www.moval.org/mvu/index.html",
        heating: "http://www.moval.org/mvu/index.html",
        "water-heater": "http://www.moval.org/mvu/index.html",
        cooling: "http://www.moval.org/mvu/index.html",
        insulation: "http://www.moval.org/mvu/index.html",
        "air-sealing": "http://www.moval.org/mvu/index.html",
        lighting: "http://www.moval.org/mvu/index.html",
        "efficient-appliances-residental": "http://www.moval.org/mvu/index.html",
        "efficient-appliances-small-business": "http://www.moval.org/mvu/index.html",
        solar: "http://www.moval.org/mvu/index.html",
        programs: "http://www.moval.org/mvu/index.html",
        "default-hee": "http://www.moval.org/mvu/index.html",
        "the-movement": "http://www.moval.org/mvu/index.html",
        "energy-heroes-fabian-rodriguez": "http://www.moval.org/mvu/index.html",
        esa: "http://www.moval.org/mvu/payment-progs.html#:~:text=The%20MVU%20Energy%20Assistance%20Program,their%20income%20and%20household%20size.&text=Eligibility%20for%20the%20program%20is,income%20of%20all%20household%20members",
        default: "http://www.moval.org/mvu/index.html",
        zip: [92324],
      },
      {
        provider: "City of Palo Alto - (CA)",
        "home-energy-efficiency-web": "http://www.cityofpaloalto.org/gov/depts/utl/default.asp",
        assessments: "http://www.cityofpaloalto.org/gov/depts/utl/default.asp",
        heating: "http://www.cityofpaloalto.org/gov/depts/utl/default.asp",
        "water-heater": "http://www.cityofpaloalto.org/gov/depts/utl/default.asp",
        cooling: "http://www.cityofpaloalto.org/gov/depts/utl/default.asp",
        insulation: "http://www.cityofpaloalto.org/gov/depts/utl/default.asp",
        "air-sealing": "http://www.cityofpaloalto.org/gov/depts/utl/default.asp",
        lighting: "http://www.cityofpaloalto.org/gov/depts/utl/default.asp",
        "efficient-appliances-residental": "http://www.cityofpaloalto.org/gov/depts/utl/default.asp",
        "efficient-appliances-small-business": "http://www.cityofpaloalto.org/gov/depts/utl/default.asp",
        solar: "http://www.cityofpaloalto.org/gov/depts/utl/default.asp",
        programs: "http://www.cityofpaloalto.org/gov/depts/utl/default.asp",
        "default-hee": "http://www.cityofpaloalto.org/gov/depts/utl/default.asp",
        "the-movement": "http://www.cityofpaloalto.org/gov/depts/utl/default.asp",
        "energy-heroes-fabian-rodriguez": "http://www.cityofpaloalto.org/gov/depts/utl/default.asp",
        esa: "https://www.cityofpaloalto.org/Departments/Utilities/Residential/Utilities-Assistance/Residential-Energy-Assistance-Program-REAP",
        default: "http://www.cityofpaloalto.org/gov/depts/utl/default.asp",
        zip: [94020],
      },
      {
        provider: "City of Pasadena - (CA)",
        "home-energy-efficiency-web": "http://cityofpasadena.net/waterandpower/",
        assessments: "http://cityofpasadena.net/waterandpower/",
        heating: "http://cityofpasadena.net/waterandpower/",
        "water-heater": "http://cityofpasadena.net/waterandpower/",
        cooling: "http://cityofpasadena.net/waterandpower/",
        insulation: "http://cityofpasadena.net/waterandpower/",
        "air-sealing": "http://cityofpasadena.net/waterandpower/",
        lighting: "http://cityofpasadena.net/waterandpower/",
        "efficient-appliances-residental": "http://cityofpasadena.net/waterandpower/",
        "efficient-appliances-small-business": "http://cityofpasadena.net/waterandpower/",
        solar: "http://cityofpasadena.net/waterandpower/",
        programs: "http://cityofpasadena.net/waterandpower/",
        "default-hee": "http://cityofpasadena.net/waterandpower/",
        "the-movement": "http://cityofpasadena.net/waterandpower/",
        "energy-heroes-fabian-rodriguez": "http://cityofpasadena.net/waterandpower/",
        esa: "https://ww5.cityofpasadena.net/water-and-power/low-income/",
        default: "http://cityofpasadena.net/waterandpower/",
        zip: [90041],
      },
      {
        provider: "City of Redding - (CA)",
        "home-energy-efficiency-web": "http://www.cityofredding.org/departments/redding-electric-utility",
        assessments: "http://www.cityofredding.org/departments/redding-electric-utility",
        heating: "http://www.cityofredding.org/departments/redding-electric-utility",
        "water-heater": "http://www.cityofredding.org/departments/redding-electric-utility",
        cooling: "http://www.cityofredding.org/departments/redding-electric-utility",
        insulation: "http://www.cityofredding.org/departments/redding-electric-utility",
        "air-sealing": "http://www.cityofredding.org/departments/redding-electric-utility",
        lighting: "http://www.cityofredding.org/departments/redding-electric-utility",
        "efficient-appliances-residental": "http://www.cityofredding.org/departments/redding-electric-utility",
        "efficient-appliances-small-business": "http://www.cityofredding.org/departments/redding-electric-utility",
        solar: "http://www.cityofredding.org/departments/redding-electric-utility",
        programs: "http://www.cityofredding.org/departments/redding-electric-utility",
        "default-hee": "http://www.cityofredding.org/departments/redding-electric-utility",
        "the-movement": "http://www.cityofredding.org/departments/redding-electric-utility",
        "energy-heroes-fabian-rodriguez": "http://www.cityofredding.org/departments/redding-electric-utility",
        esa: "https://www.cityofredding.org/departments/redding-electric-utility/reu-pages/utility-assistance-programs",
        default: "http://www.cityofredding.org/departments/redding-electric-utility",
        zip: [96001],
      },
      {
        provider: "City of Riverside - (CA)",
        "home-energy-efficiency-web": "http://www.riversideca.gov/utilities/",
        assessments: "http://www.riversideca.gov/utilities/",
        heating: "http://www.riversideca.gov/utilities/",
        "water-heater": "http://www.riversideca.gov/utilities/",
        cooling: "http://www.riversideca.gov/utilities/",
        insulation: "http://www.riversideca.gov/utilities/",
        "air-sealing": "http://www.riversideca.gov/utilities/",
        lighting: "http://www.riversideca.gov/utilities/",
        "efficient-appliances-residental": "http://www.riversideca.gov/utilities/",
        "efficient-appliances-small-business": "http://www.riversideca.gov/utilities/",
        solar: "http://www.riversideca.gov/utilities/",
        programs: "http://www.riversideca.gov/utilities/",
        "default-hee": "http://www.riversideca.gov/utilities/",
        "the-movement": "http://www.riversideca.gov/utilities/",
        "energy-heroes-fabian-rodriguez": "http://www.riversideca.gov/utilities/",
        esa: "https://riverside.networkofcare.org/aging/services/agency.aspx?pid=RIVERSIDECOUNTYCOMMUNITYACTIONPARTNERSHIPRIVERSIDEHomeEnergyAssistanceProgramHEAP_38_1_0",
        default: "http://www.riversideca.gov/utilities/",
        zip: [92262],
      },
      {
        provider: "City of Roseville - (CA)",
        "home-energy-efficiency-web": "http://www.roseville.ca.us/electric/",
        assessments: "http://www.roseville.ca.us/electric/",
        heating: "http://www.roseville.ca.us/electric/",
        "water-heater": "http://www.roseville.ca.us/electric/",
        cooling: "http://www.roseville.ca.us/electric/",
        insulation: "http://www.roseville.ca.us/electric/",
        "air-sealing": "http://www.roseville.ca.us/electric/",
        lighting: "http://www.roseville.ca.us/electric/",
        "efficient-appliances-residental": "http://www.roseville.ca.us/electric/",
        "efficient-appliances-small-business": "http://www.roseville.ca.us/electric/",
        solar: "http://www.roseville.ca.us/electric/",
        programs: "http://www.roseville.ca.us/electric/",
        "default-hee": "http://www.roseville.ca.us/electric/",
        "the-movement": "http://www.roseville.ca.us/electric/",
        "energy-heroes-fabian-rodriguez": "http://www.roseville.ca.us/electric/",
        esa: "https://www.energyassistance.us/ci/ca-roseville",
        default: "http://www.roseville.ca.us/electric/",
        zip: [95610],
      },
      {
        provider: "City of Santa Clara - (CA)",
        "home-energy-efficiency-web": "http://www.siliconvalleypower.com/home",
        assessments: "http://www.siliconvalleypower.com/home",
        heating: "http://www.siliconvalleypower.com/home",
        "water-heater": "http://www.siliconvalleypower.com/home",
        cooling: "http://www.siliconvalleypower.com/home",
        insulation: "http://www.siliconvalleypower.com/home",
        "air-sealing": "http://www.siliconvalleypower.com/home",
        lighting: "http://www.siliconvalleypower.com/home",
        "efficient-appliances-residental": "http://www.siliconvalleypower.com/home",
        "efficient-appliances-small-business": "http://www.siliconvalleypower.com/home",
        solar: "http://www.siliconvalleypower.com/home",
        programs: "http://www.siliconvalleypower.com/home",
        "default-hee": "http://www.siliconvalleypower.com/home",
        "the-movement": "http://www.siliconvalleypower.com/home",
        "energy-heroes-fabian-rodriguez": "http://www.siliconvalleypower.com/home",
        esa: "https://sanjosecleanenergy.org/payment-assistance-programs/",
        default: "http://www.siliconvalleypower.com/home",
        zip: [94085],
      },
      {
        provider: "City of Shasta Lake - (CA)",
        "home-energy-efficiency-web": "http://cityofshastalake.org/",
        assessments: "http://cityofshastalake.org/",
        heating: "http://cityofshastalake.org/",
        "water-heater": "http://cityofshastalake.org/",
        cooling: "http://cityofshastalake.org/",
        insulation: "http://cityofshastalake.org/",
        "air-sealing": "http://cityofshastalake.org/",
        lighting: "http://cityofshastalake.org/",
        "efficient-appliances-residental": "http://cityofshastalake.org/",
        "efficient-appliances-small-business": "http://cityofshastalake.org/",
        solar: "http://cityofshastalake.org/",
        programs: "http://cityofshastalake.org/",
        "default-hee": "http://cityofshastalake.org/",
        "the-movement": "http://cityofshastalake.org/",
        "energy-heroes-fabian-rodriguez": "http://cityofshastalake.org/",
        esa: "https://cityofshastalake.org/870/Efficiency-and-Rebates",
        default: "http://cityofshastalake.org/",
        zip: [96003],
      },
      {
        provider: "City of Ukiah - (CA)",
        "home-energy-efficiency-web": "http://www.cityofukiah.com/electric-utility/",
        assessments: "http://www.cityofukiah.com/electric-utility/",
        heating: "http://www.cityofukiah.com/electric-utility/",
        "water-heater": "http://www.cityofukiah.com/electric-utility/",
        cooling: "http://www.cityofukiah.com/electric-utility/",
        insulation: "http://www.cityofukiah.com/electric-utility/",
        "air-sealing": "http://www.cityofukiah.com/electric-utility/",
        lighting: "http://www.cityofukiah.com/electric-utility/",
        "efficient-appliances-residental": "http://www.cityofukiah.com/electric-utility/",
        "efficient-appliances-small-business": "http://www.cityofukiah.com/electric-utility/",
        solar: "http://www.cityofukiah.com/electric-utility/",
        programs: "http://www.cityofukiah.com/electric-utility/",
        "default-hee": "http://www.cityofukiah.com/electric-utility/",
        "the-movement": "http://www.cityofukiah.com/electric-utility/",
        "energy-heroes-fabian-rodriguez": "http://www.cityofukiah.com/electric-utility/",
        esa: "http://www.cityofukiah.com/utility-services",
        default: "http://www.cityofukiah.com/electric-utility/",
        zip: [95482],
      },
      {
        provider: "City of Vernon",
        "home-energy-efficiency-web": "http://www.cityofvernon.org/departments/public-utilities",
        assessments: "http://www.cityofvernon.org/departments/public-utilities",
        heating: "http://www.cityofvernon.org/departments/public-utilities",
        "water-heater": "http://www.cityofvernon.org/departments/public-utilities",
        cooling: "http://www.cityofvernon.org/departments/public-utilities",
        insulation: "http://www.cityofvernon.org/departments/public-utilities",
        "air-sealing": "http://www.cityofvernon.org/departments/public-utilities",
        lighting: "http://www.cityofvernon.org/departments/public-utilities",
        "efficient-appliances-residental": "http://www.cityofvernon.org/departments/public-utilities",
        "efficient-appliances-small-business": "http://www.cityofvernon.org/departments/public-utilities",
        solar: "http://www.cityofvernon.org/departments/public-utilities",
        programs: "http://www.cityofvernon.org/departments/public-utilities",
        "default-hee": "http://www.cityofvernon.org/departments/public-utilities",
        "the-movement": "http://www.cityofvernon.org/departments/public-utilities",
        "energy-heroes-fabian-rodriguez": "http://www.cityofvernon.org/departments/public-utilities",
        esa: "http://cityofvernon.org/departments/public-utilities/393-customer-service/rebates-incenteves",
        default: "http://www.cityofvernon.org/departments/public-utilities",
        zip: [90023],
      },
      {
        provider: "Lassen Municipal Utility District",
        "home-energy-efficiency-web": "https://www.lmud.org/",
        assessments: "https://www.lmud.org/",
        heating: "https://www.lmud.org/",
        "water-heater": "https://www.lmud.org/",
        cooling: "https://www.lmud.org/",
        insulation: "https://www.lmud.org/",
        "air-sealing": "https://www.lmud.org/",
        lighting: "https://www.lmud.org/",
        "efficient-appliances-residental": "https://www.lmud.org/",
        "efficient-appliances-small-business": "https://www.lmud.org/",
        solar: "https://www.lmud.org/",
        programs: "https://www.lmud.org/",
        "default-hee": "https://www.lmud.org/",
        "the-movement": "https://www.lmud.org/",
        "energy-heroes-fabian-rodriguez": "https://www.lmud.org/",
        esa: "https://www.lmud.org/news-releases/lmuds-commitment-to-energy-efficiency/",
        default: "https://www.lmud.org/",
        zip: [95947],
      },
      {
        provider: "Los Angeles Department of Water & Power",
        "home-energy-efficiency-web": "https://www.ladwp.com",
        assessments: "https://www.ladwp.com",
        heating: "https://www.ladwp.com",
        "water-heater": "https://www.ladwp.com",
        cooling: "https://www.ladwp.com",
        insulation: "https://www.ladwp.com",
        "air-sealing": "https://www.ladwp.com",
        lighting: "https://www.ladwp.com",
        "efficient-appliances-residental": "https://www.ladwp.com",
        "efficient-appliances-small-business": "https://www.ladwp.com",
        solar: "https://www.ladwp.com",
        programs: "https://www.ladwp.com",
        "default-hee": "https://www.ladwp.com",
        "the-movement": "https://www.ladwp.com",
        "energy-heroes-fabian-rodriguez": "https://www.ladwp.com",
        esa: "https://www.ladwp.com/ladwp/faces/ladwp/residential/r-savemoney/r-sm-rebatesandprograms?_adf.ctrl-state=9mv9e4s93_4&_afrLoop=485094599795614",
        default: "https://www.ladwp.com",
        zip: [90001],
      },
      {
        provider: "Merced Irrigation District",
        "home-energy-efficiency-web": "http://www.mercedid.com/",
        assessments: "http://www.mercedid.com/",
        heating: "http://www.mercedid.com/",
        "water-heater": "http://www.mercedid.com/",
        cooling: "http://www.mercedid.com/",
        insulation: "http://www.mercedid.com/",
        "air-sealing": "http://www.mercedid.com/",
        lighting: "http://www.mercedid.com/",
        "efficient-appliances-residental": "http://www.mercedid.com/",
        "efficient-appliances-small-business": "http://www.mercedid.com/",
        solar: "http://www.mercedid.com/",
        programs: "http://www.mercedid.com/",
        "default-hee": "http://www.mercedid.com/",
        "the-movement": "http://www.mercedid.com/",
        "energy-heroes-fabian-rodriguez": "http://www.mercedid.com/",
        esa: "https://www.mid.org/yourhome/programs/mid_cares.html",
        default: "http://www.mercedid.com/",
        zip: [95301],
      },
      {
        provider: "Modesto Irrigation District",
        "home-energy-efficiency-web": "http://www.mid.org/default.jsp",
        assessments: "http://www.mid.org/default.jsp",
        heating: "http://www.mid.org/default.jsp",
        "water-heater": "http://www.mid.org/default.jsp",
        cooling: "http://www.mid.org/default.jsp",
        insulation: "http://www.mid.org/default.jsp",
        "air-sealing": "http://www.mid.org/default.jsp",
        lighting: "http://www.mid.org/default.jsp",
        "efficient-appliances-residental": "http://www.mid.org/default.jsp",
        "efficient-appliances-small-business": "http://www.mid.org/default.jsp",
        solar: "http://www.mid.org/default.jsp",
        programs: "http://www.mid.org/default.jsp",
        "default-hee": "http://www.mid.org/default.jsp",
        "the-movement": "http://www.mid.org/default.jsp",
        "energy-heroes-fabian-rodriguez": "http://www.mid.org/default.jsp",
        esa: "https://www.mid.org/yourhome/programs/default.html",
        default: "http://www.mid.org/default.jsp",
        zip: [95215],
      },
      {
        provider: "Sacramento Municipal Util Dist",
        "home-energy-efficiency-web": "https://www.smud.org/en/index.htm",
        assessments: "https://www.smud.org/en/index.htm",
        heating: "https://www.smud.org/en/index.htm",
        "water-heater": "https://www.smud.org/en/index.htm",
        cooling: "https://www.smud.org/en/index.htm",
        insulation: "https://www.smud.org/en/index.htm",
        "air-sealing": "https://www.smud.org/en/index.htm",
        lighting: "https://www.smud.org/en/index.htm",
        "efficient-appliances-residental": "https://www.smud.org/en/index.htm",
        "efficient-appliances-small-business": "https://www.smud.org/en/index.htm",
        solar: "https://www.smud.org/en/index.htm",
        programs: "https://www.smud.org/en/index.htm",
        "default-hee": "https://www.smud.org/en/index.htm",
        "the-movement": "https://www.smud.org/en/index.htm",
        "energy-heroes-fabian-rodriguez": "https://www.smud.org/en/index.htm",
        esa: "https://www.smud.org/en/Rate-Information/Low-income-and-nonprofits",
        default: "https://www.smud.org/en/index.htm",
        zip: [94203],
      },
      {
        provider: "Surprise Valley Electrification",
        "home-energy-efficiency-web": "http://www.surprisevalleyelectric.org/",
        assessments: "http://www.surprisevalleyelectric.org/",
        heating: "http://www.surprisevalleyelectric.org/",
        "water-heater": "http://www.surprisevalleyelectric.org/",
        cooling: "http://www.surprisevalleyelectric.org/",
        insulation: "http://www.surprisevalleyelectric.org/",
        "air-sealing": "http://www.surprisevalleyelectric.org/",
        lighting: "http://www.surprisevalleyelectric.org/",
        "efficient-appliances-residental": "http://www.surprisevalleyelectric.org/",
        "efficient-appliances-small-business": "http://www.surprisevalleyelectric.org/",
        solar: "http://www.surprisevalleyelectric.org/",
        programs: "http://www.surprisevalleyelectric.org/",
        "default-hee": "http://www.surprisevalleyelectric.org/",
        "the-movement": "http://www.surprisevalleyelectric.org/",
        "energy-heroes-fabian-rodriguez": "http://www.surprisevalleyelectric.org/",
        esa: "https://www.surprisevalleyelectric.org/node/48",
        default: "http://www.surprisevalleyelectric.org/",
        zip: [96006],
      },
      {
        provider: "Truckee Donner P U D",
        "home-energy-efficiency-web": "http://www.tdpud.org/",
        assessments: "http://www.tdpud.org/",
        heating: "http://www.tdpud.org/",
        "water-heater": "http://www.tdpud.org/",
        cooling: "http://www.tdpud.org/",
        insulation: "http://www.tdpud.org/",
        "air-sealing": "http://www.tdpud.org/",
        lighting: "http://www.tdpud.org/",
        "efficient-appliances-residental": "http://www.tdpud.org/",
        "efficient-appliances-small-business": "http://www.tdpud.org/",
        solar: "http://www.tdpud.org/",
        programs: "http://www.tdpud.org/",
        "default-hee": "http://www.tdpud.org/",
        "the-movement": "http://www.tdpud.org/",
        "energy-heroes-fabian-rodriguez": "http://www.tdpud.org/",
        esa: "https://www.tdpud.org/customer-service/billing-options/assistance-programs",
        default: "http://www.tdpud.org/",
        zip: [96160],
      },
      {
        provider: "Turlock Irrigation District",
        "home-energy-efficiency-web": "http://www.tid.org/",
        assessments: "http://www.tid.org/",
        heating: "http://www.tid.org/",
        "water-heater": "http://www.tid.org/",
        cooling: "http://www.tid.org/",
        insulation: "http://www.tid.org/",
        "air-sealing": "http://www.tid.org/",
        lighting: "http://www.tid.org/",
        "efficient-appliances-residental": "http://www.tid.org/",
        "efficient-appliances-small-business": "http://www.tid.org/",
        solar: "http://www.tid.org/",
        programs: "http://www.tid.org/",
        "default-hee": "http://www.tid.org/",
        "the-movement": "http://www.tid.org/",
        "energy-heroes-fabian-rodriguez": "http://www.tid.org/",
        esa: "https://www.tid.org/customer-service/payment-assistance-programs/",
        default: "http://www.tid.org/",
        zip: [95303],
      },
      {
        provider: "Valley Electric Assn, Inc",
        "home-energy-efficiency-web": "http://www.vea.coop/",
        assessments: "http://www.vea.coop/",
        heating: "http://www.vea.coop/",
        "water-heater": "http://www.vea.coop/",
        cooling: "http://www.vea.coop/",
        insulation: "http://www.vea.coop/",
        "air-sealing": "http://www.vea.coop/",
        lighting: "http://www.vea.coop/",
        "efficient-appliances-residental": "http://www.vea.coop/",
        "efficient-appliances-small-business": "http://www.vea.coop/",
        solar: "http://www.vea.coop/",
        programs: "http://www.vea.coop/",
        "default-hee": "http://www.vea.coop/",
        "the-movement": "http://www.vea.coop/",
        "energy-heroes-fabian-rodriguez": "http://www.vea.coop/",
        esa: "https://vea.coop/resources/energy-assistance/",
        default: "http://www.vea.coop/",
        zip: [92328],
      },
      {
        provider: "Imperial Irrigation District",
        "home-energy-efficiency-web": "http://www.iid.com/",
        assessments: "http://www.iid.com/",
        heating: "http://www.iid.com/",
        "water-heater": "http://www.iid.com/",
        cooling: "http://www.iid.com/",
        insulation: "http://www.iid.com/",
        "air-sealing": "http://www.iid.com/",
        lighting: "http://www.iid.com/",
        "efficient-appliances-residental": "http://www.iid.com/",
        "efficient-appliances-small-business": "http://www.iid.com/",
        solar: "http://www.iid.com/",
        programs: "http://www.iid.com/",
        "default-hee": "http://www.iid.com/",
        "the-movement": "http://www.iid.com/",
        "energy-heroes-fabian-rodriguez": "http://www.iid.com/",
        esa: "https://www.iid.com/customer-service/payment-assistance",
        default: "http://www.iid.com/",
        zip: [91934],
      },
      {
        provider: "Plumas-Sierra Rural Elec Coop",
        "home-energy-efficiency-web": "http://www.psrec.coop/",
        assessments: "http://www.psrec.coop/",
        heating: "http://www.psrec.coop/",
        "water-heater": "http://www.psrec.coop/",
        cooling: "http://www.psrec.coop/",
        insulation: "http://www.psrec.coop/",
        "air-sealing": "http://www.psrec.coop/",
        lighting: "http://www.psrec.coop/",
        "efficient-appliances-residental": "http://www.psrec.coop/",
        "efficient-appliances-small-business": "http://www.psrec.coop/",
        solar: "http://www.psrec.coop/",
        programs: "http://www.psrec.coop/",
        "default-hee": "http://www.psrec.coop/",
        "the-movement": "http://www.psrec.coop/",
        "energy-heroes-fabian-rodriguez": "http://www.psrec.coop/",
        esa: "https://www.plumascdc.org/energy.html",
        default: "http://www.psrec.coop/",
        zip: [89439],
      },
    ],
  };
};
