import Flickity from "flickity";
export default config => {
  return {
    carousel: false,
    init() {
      let mq = window.matchMedia("(max-width: 576px)");
      mq.addListener(this.setupCarousel.bind(this));
      this.setupCarousel(mq);
    },

    setupCarousel(mq) {
      if (mq.matches) {
        this.carousel = new Flickity(this.$el, {
          adaptiveHeight: false,
          cellAlign: "center",
          contain: true,
          wrapAround: true,
          autoPlay: false,
          prevNextButtons: true,
          pageDots: true,
        });
      } else {
        if (this.carousel !== false) {
          this.carousel.destroy();
          this.carousel = false;
        }
      }
    },
  };
};
