import axios from "axios";

export default () => {
  return {
    showForm: false,
    isValid: true,
    invalidInfo: false,
    invalidTerms: false,
    busy: false,
    submitted: false,
    count: 0,

    init() {},

    updateRequired() {
      setTimeout(() => {
        this.invalidInfo = this.$refs.email.value == "" && this.$refs.phone.value == "";
        this.invalidTerms = !this.$refs.terms.checked;
        this.isValid = !(this.invalidInfo || this.invalidTerms);
      }, 100);
    },

    submit() {
      var data = new FormData(this.$refs.form);

      if (!this.isValid) {
      } else {
        this.busy = true;

        axios
          .post("/", data)
          .then(response => {
            if (response.data.success === true) {
              this.count = response.data.count;
              this.submitted = true;
            } else {
              this.busy = false;
              alert(response.data.error);
            }
          })
          .catch(error => {
            this.busy = false;
          });
      }
    },
  };
};
