export default url => {
  return {
    url: url,
    init() {
      console.log("setup quiz");
      console.log(this.url);
      this.$refs.cta.addEventListener("click", this.onClick.bind(this));
    },

    onClick(event) {
      alert(`create an iframe from ${this.url}`);
      return false;
    },
  };
};
