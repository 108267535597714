export default () => {
  return {
    loaded: false,
    init() {
      setTimeout(() => {
        this.loaded = true;
        document.body.classList.remove("overflow-hidden");
        document.dispatchEvent(new Event("pageReady"));
      }, 500);
    },
  };
};
