import Alpine from "alpinejs";
import persist from "@alpinejs/persist";
import collapse from "@alpinejs/collapse";
import AOS from "aos";

Alpine.plugin(collapse);
Alpine.plugin(persist);

import header from "./modules/header.js";
import providerSearch from "./modules/provider-search.js";
import providers from "./modules/energy-providers.js";
import energySaverQuiz from "./modules/energy-saver-quiz.js";
import joinTheMovement from "./modules/join-the-movement.js";
import smallBusiness from "./modules/small-business.js";
import mobileCarousel from "./modules/mobile-carousel.js";
import carouselFA from "./modules/carousel-fa-v4.js";
import returnToTop from "./modules/return-to-top.js";
import alertBar from "./modules/alert-bar.js";
import footerSection from "./modules/footer-section.js";
import preloader from "./modules/preloader";

window.Alpine = Alpine;

// setup for AlpineJs
Alpine.data("header", header);
Alpine.data("energySaverQuiz", energySaverQuiz);
Alpine.data("mobileCarousel", mobileCarousel);
Alpine.data("carouselFA", carouselFA);
Alpine.data("joinTheMovement", joinTheMovement);
Alpine.data("smallBusiness", smallBusiness);
Alpine.data("providerSearch", providerSearch);
Alpine.data("returnToTop", returnToTop);
Alpine.data("alertBar", alertBar);
Alpine.data("footerSection", footerSection);
Alpine.data("preloader", preloader);

Alpine.store("providers", providers());

Alpine.start();

export default class Main {
  // Run all initialization things
  init() {
    AOS.init({
      startEvent: "pageReady",
    });
  }
}

const main = new Main();
main.init();
