export default () => {
  return {
    open: true,
    mediaQuery: false,
    clickable: false,

    init() {
      this.mediaQuery = window.matchMedia("(min-width: 992px)");
      this.mediaQuery.addListener(this.handleMediaQueryChange.bind(this));
      this.handleMediaQueryChange(this.mediaQuery);
    },

    toggle() {
      if (this.clickable) {
        this.open = !this.open;
      }
    },

    handleMediaQueryChange(e) {
      if (e.matches) {
        this.open = true;
        this.clickable = false;
      } else {
        this.open = false;
        this.clickable = true;
      }
    },
  };
};
