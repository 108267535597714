export default function (alertBar) {
  return {
    alertBar: alertBar,
    closed: false,
    alerts: this.$persist(JSON.stringify({})),

    init() {
      var alerts = JSON.parse(this.alerts);
      if (this.alertBar in alerts && alerts[this.alertBar] === true) {
        this.closed = true;
      }
    },

    close() {
      var alerts = JSON.parse(this.alerts);
      alerts[this.alertBar] = true;
      this.alerts = JSON.stringify(alerts);
      this.closed = true;
    },
  };
}
